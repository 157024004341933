import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import BrandComponent from "../BrandComponent";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { sendResetPasswordRequest } from "../../../store/user/UserActions";
import { validateEmail } from "../../../CommonMethods";

const ResetPassword = () => {
  const [email, setEmail] = useState();

  const [isEmailValid, setIsEmailValid] = useState(false);

  const loading = useSelector((state) => state.user.loading);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const onSubmit = (e) => {
    e.preventDefault();
    if (isEmailValid) {
      dispatch(sendResetPasswordRequest({ email: email }));
      navigate("/");
    }
  };

  useEffect(() => {
    setIsEmailValid(validateEmail(email));
  }, [email]);

  return (
    <div className="authpage">
      <Container>
        <Row>
          <BrandComponent></BrandComponent>
          <Col sm={6} className="authform">
            <h3>RESET PASSWORD</h3>
            <Form onSubmit={(e) => onSubmit(e)}>
              <Form.Group className="mb-3">
                <Form.Label>Email </Form.Label>
                <Form.Control
                  type="text"
                  name="email"
                  className={
                    isEmailValid
                      ? "custom-validate-class-valid"
                      : "me-2  custom-validate-class-invalid"
                  }
                  isInvalid={!isEmailValid}
                  required
                  maxLength={64}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter a Valid Email Address
                </Form.Control.Feedback>
              </Form.Group>
              <Button
                className="form-submit-btn"
                variant="primary"
                type="submit"
              >
                {loading ? "..." : "SEND REQUEST"}
              </Button>
            </Form>
            <br />
            <p>
              Have an account? <Link to={"/login"}>Login</Link>
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ResetPassword;
