import React from "react";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";

const Sidebar = ({ links }) => {
  return (
    <Nav className="col-md-12 d-none d-md-block bg-light sidebar">
      {links.map((element, index) => {
        return (
          <Nav.Item key={index}>
            <Link to={element.link} className="nav-link">
              {element.title}
            </Link>
            {/* <Nav.Link href={element.link}> {element.title} </Nav.Link> */}
          </Nav.Item>
        );
      })}
    </Nav>
  );
};

export default Sidebar;
