import { createSlice } from "@reduxjs/toolkit";
import { fetchData } from "./DataAction";

const initialState = {
  loading: false,
  brands: [],
  categories: [],
  colors: [],
  seats: [],
  error: null,
};

const dataSlice = createSlice({
  name: "data",
  initialState: initialState,
  reducers: {},
  extraReducers: (builders) => {
    builders.addCase(fetchData.pending, (state, action) => {
      state.loading = true;
    });
    builders.addCase(fetchData.fulfilled, (state, action) => {
      let data = action.payload;
      state.loading = false;
      state.error = null;
      state.brands = data["brands"];
      state.colors = data["colors"];
      state.seats = data["seats"];
      state.categories = data["categories"];
    });
    builders.addCase(fetchData.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.error?.message || "Error Fetching Data";
    });
  },
});

export default dataSlice.reducer;
