import React, { useEffect, useState } from "react";
import ProductContainer from "../../components/ProductContainer";
import axios from "axios";

const WishlistProduct = ({ product_id }) => {
  const [product, setProduct] = useState(null);

  useEffect(() => {
    async function fetchProdOnline() {
      if (product_id) {
        await axios
          .get(`${process.env.REACT_APP_API_URL}/product/${product_id}/`)
          .catch((_) => {})
          .then((response) => {
            if (response && response.status === 200) {
              setProduct(response.data);
            }
          });
      }
    }

    fetchProdOnline();
    // eslint-disable-next-line
  }, [product_id]);

  return (
    product && <ProductContainer product={product} showPercentageOff={true} />
  );
};

export default WishlistProduct;
