import { createSlice } from "@reduxjs/toolkit";
import { addWishlist, deleteWishlist, fetchWishList } from "./wishlistAction";

const initialState = {
  loading: false,
  wishlist: null,
  error: null,
};

const wishlistSlice = createSlice({
  name: "wishlist",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchWishList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchWishList.fulfilled, (state, action) => {
        state.loading = false;
        state.wishlist = action.payload;
      })
      .addCase(fetchWishList.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.error?.message || "Error Loading Wishlist";
      })
      // add wishlist
      .addCase(addWishlist.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addWishlist.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(addWishlist.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.error?.message || "Error Adding to wishlist";
      }) // delete wishlist
      .addCase(deleteWishlist.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteWishlist.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteWishlist.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.error?.message || "Error Deleting Wishlist";
      });
  },
});

export default wishlistSlice.reducer;
