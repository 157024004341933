import React, { useEffect, useState } from "react";
import { Alert, Button, Form, InputGroup, Spinner } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { Dash, Plus } from "react-bootstrap-icons";
import axios from "axios";
import { isArray } from "../../../CommonMethods";
import AdminPage from "../AdminPage";
import { useDispatch, useSelector } from "react-redux";
import { fetchData } from "../../../store/data/DataAction";
import { basicConfigMultipart } from "../../../constants";

const categoryModel = {
  id: null,
  name: "",
  top_level: false,
  sub_categories: [],
  description: "",
  image: null,
};

const CategoryForm = () => {
  const categoriesList = useSelector((state) => state.data.categories || []);

  const { pk } = useParams();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [category, setCategory] = useState(categoryModel);

  const [showError, setShowError] = useState(false);

  const [loading, setLoading] = useState(false);

  const [newImage, setNewImage] = useState(null);

  useEffect(() => {
    //   fetch the item using pk as id
    if (pk) {
      setLoading(true);
      let ctg = categoriesList.find((c) => c.id === parseInt(pk));
      if (ctg) {
        if (ctg["sub_categories"] && ctg.sub_categories.length > 0) {
          // change from this [{id:1, name:""},{id:2,name:""}] to [1,2]
          let sub_categories = [];
          ctg.sub_categories.forEach((element) => {
            if (element.id) {
              sub_categories.push(element.id);
            }
          });
          setCategory({
            ...categoryModel,
            ...ctg,
            sub_categories: sub_categories,
          });
        } else {
          setCategory({ ...categoryModel, ...ctg });
        }
      }
      setLoading(false);
    }
  }, [pk, categoriesList]);

  function handleToggleList(id, a_list) {
    let n_list = typeof a_list === "object" ? [...a_list] : [];

    let r = n_list.includes(id);

    if (r) {
      // remove
      n_list = n_list.filter((pk) => pk !== id);
    } else {
      // add

      n_list.push(id);
    }

    return n_list;
  }

  const [formValidated, setFormValidated] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation(); //  stops the bubbling of an event to parent elements, preventing any parent event handlers from being executed
      setFormValidated(true);
      window.scrollTo(0, 0);
    } else {
      setShowError(false);
      setFormValidated(false);
      setLoading(true);

      const formData = new FormData();

      for (const [key, value] of Object.entries(category)) {
        if (value != null && key !== "image") {
          //   image sent should be a file not a string, so we'll append image elsewhere
          if (isArray(value)) {
            value.forEach((i) => {
              formData.append(`${key}`, i);
            });
          } else {
            formData.append(`${key}`, value);
          }
        }
      }

      // let slug = category.name.toLowerCase();

      // formData.set("slug", slug);

      if (newImage != null) {
        // change image key value to be file
        formData.append("image", newImage);
      }

      if (pk) {
        //   updating a db object
        await axios
          .put(
            `${process.env.REACT_APP_API_URL}/category/${pk}/`,
            formData,
            basicConfigMultipart
          )
          .catch((_) => {
            setShowError(true);
          })
          .then((response) => {
            if (response && response.status === 200) {
              setLoading(false);
              dispatch(fetchData());
              navigate("/admin/categories/");
            } else {
              setLoading(false);
              setShowError(true);
            }
          });
      } else {
        await axios
          .post(
            `${process.env.REACT_APP_API_URL}/category/`,
            formData,
            basicConfigMultipart
          )
          .catch((_) => {
            setShowError(true);
          })
          .then((response) => {
            if (response && response.status === 200) {
              dispatch(fetchData());
              navigate("/admin/categories/");
            } else {
              setShowError(true);
            }
          });
        setLoading(false);
      }
    }
  }

  function handleFileChange(e) {
    if (e.target.files) {
      setCategory({ ...category, image: e.target.files[0] });
      setNewImage(e.target.files[0]);
    }
  }

  return (
    <AdminPage>
      {showError && (
        <Alert
          variant="danger"
          onClose={() => {
            setShowError(false);
          }}
          dismissible
        >
          {window.scrollTo(0, 0)}
          <Alert.Heading>Error. Try Again.</Alert.Heading>
        </Alert>
      )}
      {loading && <Spinner animation="grow" />}
      <Form
        noValidate
        validated={formValidated}
        onSubmit={(e) => handleSubmit(e)}
        encType="multipart/form-data"
      >
        <Form.Group className="mb-3">
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            required
            name="Name"
            maxLength={60}
            value={category.name ? category.name : ""}
            onChange={(e) =>
              setCategory({ ...category, name: e.target.value.trimStart() })
            }
          />
          <Form.Control.Feedback type="invalid">
            Provide Category Name
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3">
          <label htmlFor="description">Description</label>
          <textarea
            name="description"
            className="form-control"
            value={category.description ? category.description : ""}
            rows={4}
            onChange={(e) =>
              setCategory({
                ...category,
                description: e.target.value.trimStart(),
              })
            }
            required
          ></textarea>
          <Form.Control.Feedback type="invalid">
            Provide a brief description
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Check
            type="checkbox"
            label="Top Level"
            checked={category.top_level}
            onChange={(e) =>
              setCategory({ ...category, top_level: e.target.checked })
            }
            // disabled
          />
        </Form.Group>
        {newImage ? (
          <Form.Group className="mb-3">
            <label htmlFor="current-svg-image">Current Icon</label>
            <img
              src={URL.createObjectURL(newImage)}
              className="form-svg-display"
              name="current-svg-image"
              alt=""
            />
          </Form.Group>
        ) : category.image ? (
          <Form.Group className="mb-3">
            <label htmlFor="current-svg-image">Current Icon</label>
            <img
              src={`${process.env.REACT_APP_API_URL}${category.image}`}
              name="current-svg-image"
              className="form-svg-display"
              alt=""
            />
          </Form.Group>
        ) : (
          <></>
        )}
        <Form.Group className="mb-3">
          <label htmlFor="svg-image">Image Icon</label>
          <input
            //   multiple
            type="file"
            name="svg-image"
            className="form-control"
            accept="image/svg"
            onChange={(e) => {
              handleFileChange(e);
            }}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Sub Categories</Form.Label>
          <div className="form-control-in-div">
            <div name="sub-categories" className="toggle_list_div">
              {categoriesList.map((c, index) => {
                if (!c.top_level && c.name !== category.name) {
                  return (
                    <Button
                      key={index}
                      variant="outline-secondary"
                      className="toggle_list_btn"
                      onClick={() =>
                        setCategory({
                          ...category,
                          sub_categories: handleToggleList(
                            c.id,
                            category.sub_categories
                          ),
                        })
                      }
                      style={{
                        backgroundColor: category.sub_categories.includes(c.id)
                          ? "#c7e6c7"
                          : "var(--bs-btn-bg)",
                      }}
                    >
                      {c.name}{" "}
                      {category.sub_categories.includes(c.id) ? (
                        <Dash size={25} />
                      ) : (
                        <Plus size={25} />
                      )}
                    </Button>
                  );
                }
                return <></>;
              })}
            </div>
            <InputGroup></InputGroup>
          </div>
        </Form.Group>
        <Button className="form-submit-btn" variant="primary" type="submit">
          {pk ? "Save" : "Add"}
        </Button>
      </Form>
    </AdminPage>
  );
};

export default CategoryForm;
