import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import SecondProductContainer from "../../components/SecondProductContainer";
import axios from "axios";

const BestSellers = () => {
  const [loading, setLoading] = useState(false);

  const [bestSales, setBestSales] = useState([]);

  async function fetchBestSellProducts() {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/filter/?bestseller=1&limit=4`)
      .catch((_) => {
        setLoading(false);
      })
      .then((response) => {
        if (response && response.status === 200) {
          setBestSales(response.data);
        }
        setLoading(false);
      });
  }

  // fetch first {limit}(page 1) then on scroll, next until {limit} is not filled up
  // have a bottom component [Loading More..], on observed, load next page,

  useEffect(() => {
    fetchBestSellProducts();
  }, []);

  return (
    <Container className="best_sellers">
      <h3 style={{ fontWeight: "bold" }}>Best Sellers</h3>
      <Row>
        <Col sm={4} className="best_seller_image">
          <h2>Thika Road Furnitures</h2>
          <p>
            <span style={{ color: "darkorange" }}>
              Free Shipping Available{" "}
            </span>
            on many items.
          </p>
          <i>T & C's apply</i>
        </Col>
        <Col sm={8} className="best_seller_products">
          <Row>
            {bestSales.map((product, i) => {
              return (
                <SecondProductContainer
                  key={i}
                  product={product}
                  showPercentageOff={true}
                />
              );
            })}
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default BestSellers;
