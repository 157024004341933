import React from "react";
import { Button, Form } from "react-bootstrap";
import { Dash, Plus } from "react-bootstrap-icons";

const MinusPlusContainer = ({
  val,
  onChange,
  onAdd,
  onMinus,
  max,
  backOrdersAllowed = false,
  disabled = false,
}) => {
  return (
    <div className="minus-plus-container">
      <Button variant="outline-secondary" onClick={onMinus} disabled={disabled}>
        <Dash size={25} />
      </Button>
      <Form.Control
        type="number"
        value={val}
        max={backOrdersAllowed ? 99999999 : max ?? 0}
        onChange={onChange}
        disabled={disabled}
      />
      <Button variant="outline-secondary" onClick={onAdd} disabled={disabled}>
        <Plus size={25} />
      </Button>
    </div>
  );
};

export default MinusPlusContainer;
