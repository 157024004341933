import React from "react";
import { X } from "react-bootstrap-icons";
import ProductMainDetails from "../pages/product/ProductMainDetails";
import { Container } from "react-bootstrap";

const ProductQuickView = ({ product, show, setShow }) => {
  return (
    <div className={show ? "quick_view quick_view_show" : "quick_view"}>
      <X
        className="quick_view_close"
        size={40}
        onClick={() => {
          setShow(false);
        }}
      />

      <div className="quick_view_spec product_page">
        <Container className="custom_container">
          <ProductMainDetails product={product} />
        </Container>
      </div>
    </div>
  );
};

export default ProductQuickView;
