import React, { useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { Search } from "react-bootstrap-icons";
import { useLocation, useNavigate } from "react-router-dom";

const MainSearch = ({ placeholder = "Search.." }) => {
  const location = useLocation();

  const navigate = useNavigate();

  const urlParams = new URLSearchParams(location.search);

  const [searchTerm, setSearchTerm] = useState("");

  return (
    <Form
      className="d-flex"
      onSubmit={(e) => {
        e.preventDefault();
        urlParams.set("q", searchTerm);
        urlParams.set("page", 1);
        navigate(`?${urlParams.toString()}`);
      }}
    >
      <InputGroup>
        <InputGroup.Text id="search-1">
          <Search />
        </InputGroup.Text>
        <Form.Control
          type="search"
          placeholder={placeholder}
          className="me-2"
          aria-label="Search"
          aria-describedby="search-1"
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </InputGroup>
      <Button variant="warning" type="submit">
        Search
      </Button>
    </Form>
  );
};

export default MainSearch;
