import React from "react";
import { Col } from "react-bootstrap";
import trfLogo from "../../assets/images/brand.png";

const BrandComponent = () => {
  return (
    <Col sm={6} className="brandcomponent">
      <img src={trfLogo} alt="tcf" />
      <br />
      <h4>Thika Road Furnitures</h4>
      <br />
      <p>
        Join our happy 10,000+ customers who are delighted by our exceptional
        service and quality affordable furniture, creating their dream homes
        effortlessly.
      </p>
    </Col>
  );
};

export default BrandComponent;
