import React, { useState } from "react";
import { Button } from "react-bootstrap";

const PriceRange = ({ minPrice, maxPrice, setUpMin, setUpMax }) => {
  const [min, setMin] = useState(minPrice);
  const [max, setMax] = useState(maxPrice);

  function handleMinChange(val) {
    if (val <= max) {
      if (val < 0) {
        setMin(0);
      } else {
        setMin(val);
      }
    } else {
      setMin(max);
    }
  }
  function handleMaxChange(val) {
    if (val < min) {
      setMax(min);
    } else if (val > 500000) {
      setMax(500000);
    } else {
      setMax(val);
    }
  }

  function handleMinMaxChange() {
    setUpMin(min);
    setUpMax(max);
  }
  return (
    <div className="range">
      <div className="range-slider">
        <span
          className="range-selected"
          style={{
            left: `${(min / 500000) * 100}%`,
            right: `${100 - (max / 500000) * 100}%`,
          }}
        ></span>
      </div>
      <div className="range-slider-inputs">
        <input
          type="range"
          min={0}
          max={500000}
          step={500}
          value={min}
          onChange={(e) => handleMinChange(parseInt(e.target.value))}
        />
        <input
          type="range"
          min={0}
          max={500000}
          step={500}
          value={max}
          onChange={(e) => handleMaxChange(parseInt(e.target.value))}
        />
      </div>
      <div className="range-price-input">
        <input
          type="number"
          value={min}
          onChange={(e) => handleMinChange(parseInt(e.target.value))}
        />{" "}
        -
        <input
          type="number"
          value={max}
          onChange={(e) => handleMaxChange(parseInt(e.target.value))}
        />
      </div>
      <Button variant="outline-success" onClick={(e) => handleMinMaxChange()}>
        Filter
      </Button>
    </div>
  );
};

export default PriceRange;
