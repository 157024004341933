import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { basicConfig } from "../../constants";

export const fetchWishList = createAsyncThunk(
  "wishlist/fetch",
  async (_, { getState, dispatch }) => {
    const { user } = getState();

    if (user.user?.email) {
      // logged in

      const body = JSON.stringify({ customer: user.user?.email });

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/orders/filter_wishlist/`,
        body,
        basicConfig
      );

      const res = await response.data;

      return res;
    }
    return [];
  }
);

export const addWishlist = createAsyncThunk(
  "wishlist/add",
  async ({ prod_id }, { getState, dispatch }) => {
    const { user } = getState();

    if (user.user?.email) {
      // logged in
      // console.log("User logged in");

      // console.log("Create");
      if (prod_id) {
        const body = JSON.stringify({
          customer: user.user?.email,
          product: prod_id,
        });

        await axios.post(
          `${process.env.REACT_APP_API_URL}/orders/wishlist/`,
          body,
          basicConfig
        );

        dispatch(fetchWishList());
      }
    }
  }
);

export const deleteWishlist = createAsyncThunk(
  "wishlist/delete",
  async ({ id }, { getState, dispatch }) => {
    const { user } = getState();

    if (user.user?.email) {
      // logged in

      // console.log("deleting from db");

      await axios.delete(
        `${process.env.REACT_APP_API_URL}/orders/wishlist/${id}/`,
        basicConfig
      );
    }

    dispatch(fetchWishList());
  }
);
