import React from "react";
import { Button } from "react-bootstrap";
import { Snapchat } from "react-bootstrap-icons";
import { Link } from "react-router-dom";

const PageNotFound = () => {
  return (
    <div className="page_not_found">
      <center>
        <Snapchat size={60} />
        <br />
        <br />
        <h3>404. Page Not Found.</h3>
        <br />
        <Link to={"/"}>
          <Button> GO TO HOMEPAGE</Button>
        </Link>
      </center>
    </div>
  );
};

export default PageNotFound;
