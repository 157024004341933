import React from "react";
import { Col, Table } from "react-bootstrap";
import OrderLineItem from "./OrderLineItem";
import { localizeDate } from "../../common_methods/formatDate";

const OrderItem = ({ order_item }) => {
  return (
    <Col sm={6} className="order_item">
      <Table striped bordered>
        <tbody>
          <tr>
            <td>#id</td>
            <td>{order_item?.id}</td>
          </tr>
          <tr>
            <td>Status</td>
            <td>{order_item?.status}</td>
          </tr>
          <tr>
            <td>Currency</td>
            <td>{order_item?.currency}</td>
          </tr>
          <tr>
            <td>Date Created</td>
            <td>{localizeDate(order_item?.date_created_gmt)}</td>
          </tr>
          <tr>
            <td>Shipping Charge</td>
            <td>{order_item?.shipping_total}</td>
          </tr>
          <tr>
            <td>Grand Total</td>
            <td>{order_item?.total}</td>
          </tr>
          <tr>
            <td>Note</td>
            <td>{order_item?.customer_note ?? ""}</td>
          </tr>
          <tr>
            <td>Payment Method</td>
            <td>{order_item?.payment_method}</td>
          </tr>
          <tr>
            <td>Customer</td>
            <td>{order_item?.customer ?? "Guest"}</td>
          </tr>
          <tr>
            <td>Billing</td>
            <td>
              <Table>
                <tbody>
                  <tr>
                    <td>Names</td>
                    <td>
                      {order_item?.billing?.first_name}{" "}
                      {order_item?.billing?.last_name}
                    </td>
                  </tr>
                  <tr>
                    <td>Email</td>
                    <td>{order_item?.billing?.email}</td>
                  </tr>
                  <tr>
                    <td>Phone Number</td>
                    <td>{order_item?.billing?.phone_number}</td>
                  </tr>
                  <tr>
                    <td>Address</td>
                    <td>
                      {order_item?.billing?.address},{" "}
                      {order_item?.billing?.town}, {order_item?.billing?.county}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </td>
          </tr>
          <tr>
            <td>Shipping</td>
            <td>
              {order_item?.shipping != null ? (
                <Table>
                  <tbody>
                    <tr>
                      <td>Names</td>
                      <td>
                        {order_item?.shipping?.first_name}{" "}
                        {order_item?.shipping?.last_name}
                      </td>
                    </tr>
                    <tr>
                      <td>Email</td>
                      <td>{order_item?.shipping?.email}</td>
                    </tr>
                    <tr>
                      <td>Phone Number</td>
                      <td>{order_item?.shipping?.phone_number}</td>
                    </tr>
                    <tr>
                      <td>Address</td>
                      <td>
                        {order_item?.shipping?.address},{" "}
                        {order_item?.shipping?.town},{" "}
                        {order_item?.shipping?.county}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              ) : (
                <>Pick Up At Station</>
              )}
            </td>
          </tr>
          <tr>
            <td>Line Items</td>
            <td>
              {(order_item?.line_items ?? []).map((item, index) => {
                return (
                  <OrderLineItem key={index} item={item} index={index + 1} />
                );
              })}
            </td>
          </tr>
        </tbody>
      </Table>
    </Col>
  );
};

export default OrderItem;
