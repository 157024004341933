import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import ProductContainer from "../../components/ProductContainer";
import axios from "axios";

const DealsOfTheWeek = () => {
  const [loading, setLoading] = useState(false);

  const [dealProducts, setDealProducts] = useState([]);

  // sun is 0, sat is 6
  const [date, setDate] = useState(new Date());

  async function fetchDealProducts() {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/deals/?display=1&limit=3`)
      .catch((_) => {
        setLoading(false);
      })
      .then((response) => {
        if (response && response.status === 200) {
          setDealProducts(response.data);
        }
        setLoading(false);
      });
  }

  // fetch first {limit}(page 1) then on scroll, next until {limit} is not filled up
  // have a bottom component [Loading More..], on observed, load next page,

  useEffect(() => {
    fetchDealProducts();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setDate(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Row className="deals_of_week">
      <div className="deals_header">
        <h1>Deals of The Week</h1>
        <div className="deals_time">
          <div className="deals_time_box">
            <h2>{6 - date.getDay()}</h2>
            <h5>Days</h5>
          </div>
          :
          <div className="deals_time_box">
            <h2>{23 - date.getHours()}</h2>
            <h5>Hours</h5>
          </div>
          :
          <div className="deals_time_box">
            <h2> {59 - date.getMinutes()}</h2>
            <h5>Mins</h5>
          </div>
          :
          <div className="deals_time_box">
            <h2>{59 - date.getSeconds()} </h2>
            <h5>Secs</h5>
          </div>
        </div>
      </div>

      <Col sm={12} className="popular_content deal_week">
        {dealProducts.map((element, index) => {
          return (
            <ProductContainer
              key={index}
              product={element?.product}
              showPercentageOff={true}
            />
          );
        })}
      </Col>
    </Row>
  );
};

export default DealsOfTheWeek;
