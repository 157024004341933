import React, { useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { Dash, Plus } from "react-bootstrap-icons";
import { useSelector } from "react-redux";

const PBrandForm = ({ product, setProduct }) => {
  const brandsList = useSelector((state) => state.data.brands || []);

  const [typedBrand, setTypedBrand] = useState(
    product?.brand
      ? product.brand.name === "Other"
        ? null
        : product.brand.name
      : null
  );

  function handleBrand(str) {
    let found = brandsList.find(
      (brnd) => brnd.name.toLowerCase() === typedBrand.toLowerCase()
    );

    if (found) {
      setProduct({ ...product, brand: { ...found } });
    } else {
      setProduct({ ...product, brand: { id: null, name: str } });
    }
    // let found = false;
    // for (const brnd of brandsList) {
    //   if (brnd.name.toLowerCase() === typedBrand.toLowerCase()) {
    //     found = true;
    //     setProduct({ ...product, brand: { ...brnd } });
    //   }
    // }
    // if (!found) {
    //   setProduct({ ...product, brand: { id: null, name: str } });
    // }
  }

  // const toggleDisplay = (id) => {
  //   const elem = document.getElementById(id);
  //   if (elem) {
  //     if (elem.style.display !== "block") {
  //       elem.style.setProperty("display", "block", "important");
  //     } else {
  //       elem.style.setProperty("display", "none", "important");
  //     }
  //   }
  // };

  return (
    <Form.Group className="mb-3">
      <Form.Label>Manufacturer/Brand (Opt.)</Form.Label>
      <div className="form-control-in-div">
        <InputGroup>
          <Form.Control
            type="search"
            placeholder="Set manufacturer/brand"
            className="me-2 no_validation_needed"
            aria-label="Search"
            aria-describedby="color-1"
            value={typedBrand || ""}
            onChange={(e) => setTypedBrand(e.target.value)}
          />
          <InputGroup.Text id="color-1">
            {product?.brand === null || product?.brand?.name === "Other" ? (
              <Button
                variant="outline-secondary"
                onClick={() => {
                  if (typedBrand.length > 0) {
                    handleBrand(typedBrand);
                  }
                }}
              >
                <Plus size={25} />
              </Button>
            ) : (
              <Button
                variant="outline-secondary"
                onClick={() => {
                  setProduct({ ...product, brand: null });
                  setTypedBrand("");
                }}
              >
                <Dash size={25} />
              </Button>
            )}
          </InputGroup.Text>
        </InputGroup>
      </div>
    </Form.Group>
    // <Form.Group className="mb-3">
    //   <Form.Label>Brand/Manufacturer (Opt.) </Form.Label>
    //   <div className="form-control-in-div">
    //     <h5>
    //       {product.brand ? product.brand.name : ""}
    //       <Form.Group>
    //         <Form.Control
    //           type="text"
    //           required
    //           value={product.brand ? product.brand.name : ""}
    //           name="brand"
    //           className="input_type_hidden"
    //           onChange={() => {}}
    //         />
    //         <Form.Control.Feedback type="invalid">
    //           Select or set brand/make
    //         </Form.Control.Feedback>
    //       </Form.Group>
    //     </h5>
    //     <div className="dropdown">
    //       <InputGroup>
    //         <Form.Control
    //           type="search"
    //           placeholder="Select Make/Brand or add.."
    //           aria-label="Search"
    //           aria-describedby="tags-1"
    //           className="no_validation_needed"
    //           onFocus={() => toggleDisplay("brands_add")}
    //           value={typedBrand}
    //           onChange={(e) => {
    //             setTypedBrand(e.target.value);
    //           }}
    //         />
    //         <InputGroup.Text id="brands-1">
    //           <Button
    //             variant="outline-secondary"
    //             onClick={() => {
    //               if (typedBrand.length > 1) {
    //                 handleBrand(typedBrand);
    //                 setTypedBrand("");
    //               }
    //             }}
    //           >
    //             <Plus size={25} />
    //           </Button>
    //         </InputGroup.Text>
    //       </InputGroup>
    //       <div id="brands_add" className="drop-down-menu-custom">
    //         {brandsList.map((brand, index) => {
    //           if (brand.name.toLowerCase().includes(typedBrand.toLowerCase())) {
    //             return (
    //               <button
    //                 className="drop-down-item-custom"
    //                 variant="link"
    //                 key={index}
    //                 onClick={(e) => {
    //                   e.preventDefault();
    //                   setProduct({ ...product, brand: { ...brand } });
    //                   setTypedBrand("");
    //                   toggleDisplay("brands_add");
    //                 }}
    //               >
    //                 {brand.name}
    //               </button>
    //             );
    //           }
    //           return <></>;
    //         })}
    //       </div>
    //     </div>
    //   </div>
    // </Form.Group>
  );
};

export default PBrandForm;
