export const basicConfig = {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Token ${process.env.REACT_APP_TOKEN}`,
  },
};

export const basicConfigMultipart = {
  headers: {
    "Content-Type": "multipart/form-data",
    Authorization: `Token ${process.env.REACT_APP_TOKEN}`,
  },
};

export const newConfig = {
  headers: {
    "Content-Type": "text/plain;charset=utf-8",
    "x-content-type-options": "nosniff",
    "Access-Control-Allow-Origin": "*",
    "cache-control": "no-cache, no-store, max-age=0, must-revalidate",
    pragma: "no-cache",
    expires: "Mon, 01 Jan 1990 00:00:00 GMT",
    "x-frame-options": "SAMEORIGIN",
    "content-security-policy": "frame-ancestors 'self'",
    "x-xss-protection": "1; mode=block",
    server: "GSE",
    "alt-svc": 'h3=":443"; ma=2592000,h3-29=":443"; ma=2592000',
    "accept-ranges": "none",
    vary: "Sec-Fetch-Dest, Sec-Fetch-Mode, Sec-Fetch-Site,Accept-Encoding",
  },
};
