import React, { useEffect, useState } from "react";
import { Pagination } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";

const PagePagination = ({ page, pages }) => {
  const [pageNumbers, setPageNumbers] = useState([1]);

  const location = useLocation();

  const navigate = useNavigate();

  const urlParams = new URLSearchParams(location.search);

  useEffect(() => {
    function updatePageNumbers() {
      let a = [1];

      if (pages > 1) {
        if (pages < 5) {
          a = [];
          for (let index = 1; index <= pages; index++) {
            a.push(index);
          }
        } else {
          a = [1, 2, 3, 4, 5];
          if (page >= pages) {
            a = [];
            for (let index = 4; index >= 0; index--) {
              a.push(pages - index);
            }
          } else if (page > 5) {
            a = [];
            for (let index = 0; index < 5; index++) {
              if (page + index > pages) {
                a.unshift(page - index);
              } else {
                a.push(page + index);
              }
            }
          }
        }
      }

      return a;
    }

    setPageNumbers(updatePageNumbers());
  }, [page, pages]);

  function returnParams(page) {
    if (urlParams.has("page")) {
      urlParams.set("page", page);
    } else {
      urlParams.append("page", page);
    }
    return urlParams.toString();
  }

  function handlePageChange(e, page) {
    e.preventDefault();
    let params = returnParams(page);
    navigate(`?${params}`);
  }

  return (
    <div className="pageNumbersList">
      <Pagination>
        <Pagination.First
          href={`?${returnParams(1)}`}
          disabled={page === 1}
          onClick={(e) => handlePageChange(e, 1)}
        />
        <Pagination.Prev
          href={`?${returnParams(page > 1 ? page - 1 : 1)}`}
          disabled={page === 1}
          onClick={(e) => handlePageChange(e, page > 1 ? page - 1 : 1)}
        />
        {pageNumbers.map((e) => {
          return (
            <Pagination.Item
              key={e}
              href={`?${returnParams(e)}`}
              active={e === page}
              onClick={(ev) => handlePageChange(ev, e)}
            >
              {e}
            </Pagination.Item>
          );
        })}

        <Pagination.Next
          href={`?${returnParams(page >= pages ? pages : page + 1)}`}
          disabled={page === pages}
          onClick={(e) => handlePageChange(e, page >= pages ? pages : page + 1)}
        />
        <Pagination.Last
          href={`?${returnParams(pages)}`}
          disabled={page === pages}
          onClick={(e) => handlePageChange(e, pages)}
        />
      </Pagination>
    </div>
  );
};

export default PagePagination;
