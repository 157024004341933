import React, { useEffect, useState } from "react";
import { Alert, Button, Spinner, Table } from "react-bootstrap";
import { Check, Plus, Trash } from "react-bootstrap-icons";
import { Link, useLocation } from "react-router-dom";
import MainSearch from "../../components/MainSearch";
import PagePagination from "../../components/PagePagination";
import AdminPage from "./AdminPage";
import axios from "axios";
import { basicConfig, basicConfigMultipart } from "../../constants";

const per_page_limit = 10;

const AdminListProducts = () => {
  const location = useLocation();

  const urlParams = new URLSearchParams(location.search);

  const search = urlParams.get("q");

  const page = urlParams.get("page") || 1;

  const [productsCount, setProductsCount] = useState(1);

  const [pages, setPages] = useState(1);

  const [loading, setLoading] = useState(true);

  const [showError, setShowError] = useState(false);

  const [errorMsg, setErrorMsg] = useState("");

  const [productsList, setProductsList] = useState([]);

  const [deals, setDeals] = useState([]);

  async function fetchDeals() {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/deals/`)
      .catch((_) => {})
      .then((response) => {
        if (response && response.status === 200) {
          setDeals(response.data);
        }
      });
  }

  useEffect(() => {
    fetchDeals();
  }, []);

  async function addToDeals(id) {
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/deals/`,
        {
          product: id,
        },
        basicConfig
      )
      .catch((_) => {})
      .then((response) => {
        if (response && response.status === 200) {
          fetchDeals();
        }
      });
  }

  const fetchProducts = async (search, per_page_limit) => {
    setShowError(false);
    setLoading(true);

    let formData = new FormData();

    formData.append("limit", per_page_limit);
    formData.append("page", page);

    if (search && search.trim().length > 0) {
      formData.append("q_search", search);
    }

    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/filter/`,
        formData,
        basicConfigMultipart
      )
      .catch((_) => {
        setShowError(true);
        setErrorMsg("Error Fetching!");
      })
      .then((response) => {
        if (response && response.status === 200) {
          setProductsCount(response.data["count"]);
          setProductsList(response.data["products"]);
        } else {
          setErrorMsg("Error Fetching!");
          setShowError(true);
        }
      });

    setLoading(false);
  };

  useEffect(() => {
    fetchProducts(search, per_page_limit);
  }, [page, search]);

  useEffect(() => {
    setPages(Math.ceil(productsCount / per_page_limit));
  }, [productsCount]);

  const deleteProduct = async (id) => {
    setShowError(false);
    await axios
      .delete(`${process.env.REACT_APP_API_URL}/product/${id}/`, basicConfig)
      .catch((_) => {
        setErrorMsg("Couldn't delete. Product Tied to other Objects.");
        setShowError(true);
      })
      .then((response) => {
        if (response && response.status === 204) {
          axios
            .post(
              "https://script.google.com/macros/s/AKfycbyBtL7SsDxv4ca-nZlndelMdks072NAygTTsYAyYVrSiIMjBDrUeh_mg_1Q-Q7rOTzftw/exec?action=delete",
              JSON.stringify({ id: id }),
              {
                headers: {
                  "Content-Type": "text/plain",
                },
              }
            )
            .catch((_) => {
              // console.log(_);
            });
          fetchProducts(search, per_page_limit);
        } else {
          setErrorMsg("Couldn't delete. Product Tied to other Objects.");
          setShowError(true);
        }
      });
  };

  return (
    <AdminPage>
      {showError && (
        <Alert
          variant="danger"
          onClose={() => {
            setShowError(false);
          }}
          dismissible
        >
          <Alert.Heading>{errorMsg}</Alert.Heading>
        </Alert>
      )}
      {loading && <Spinner animation="grow" />}
      <div className="admin_content">
        <div className="admin_content_header">
          <h4>Select Product to change</h4>
          <Link to={"/admin/products/add/"}>
            <Button>
              <Plus size={25} /> Add New
            </Button>
          </Link>
        </div>
        {/* place a searchbox here */}
        <MainSearch />

        <div className="admin_content_content">
          <Table striped hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Add To Deals</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {productsList.map((prod, index) => {
                return (
                  <tr key={index}>
                    <td>{prod.id}</td>
                    <td>
                      <Link to={`/admin/products/change/${prod.id}/`}>
                        {prod.name}
                      </Link>
                    </td>
                    <td>
                      {deals.find((x) => x?.product?.id === prod.id) ? (
                        <Check />
                      ) : (
                        <Plus onClick={() => addToDeals(prod.id)} />
                      )}
                    </td>
                    <td>
                      <Trash size={23} onClick={() => deleteProduct(prod.id)} />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>

          {/* pagination here */}
          <PagePagination page={parseInt(page) || 1} pages={pages} />
        </div>
      </div>
    </AdminPage>
  );
};

export default AdminListProducts;
