import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { basicConfigMultipart } from "../../constants";

export const fetchData = createAsyncThunk("data/fetch", async () => {
  try {
    let brands = null;

    const brands_response = await axios
      .get(`${process.env.REACT_APP_API_URL}/brands/`)
      .catch((_) => {});

    if (brands_response && brands_response.status === 200) {
      brands = await brands_response.data;
    }

    let categories = null;

    const categories_response = await axios
      .get(`${process.env.REACT_APP_API_URL}/category/`)
      .catch((_) => {});

    if (categories_response && categories_response.status === 200) {
      categories = categories_response.data;
    }

    let colors = [];

    const colors_response = await axios
      .post(
        `${process.env.REACT_APP_API_URL}/filter_meta/`,
        {
          key: "color",
        },
        basicConfigMultipart
      )
      .catch((_) => {});
    if (colors_response && colors_response.status === 200) {
      colors = colors_response.data;
    }

    let seats = [];

    const seats_response = await axios
      .post(
        `${process.env.REACT_APP_API_URL}/filter_meta/`,
        {
          key: "seats",
        },
        basicConfigMultipart
      )
      .catch((_) => {});
    if (seats_response && seats_response.status === 200) {
      seats = seats_response.data;
    }

    let data = {
      colors: colors,
      brands: brands,
      seats: seats,
      categories: categories,
    };

    return data;
  } catch (_) {}
});
