import React, { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Container,
  Form,
  FormControl,
  InputGroup,
  Nav,
  Navbar,
  NavDropdown,
  Offcanvas,
} from "react-bootstrap";
import { Basket3, Headset, Heart, Person, Search } from "react-bootstrap-icons";
import logo from "../logo.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginUser, logoutUser } from "../store/user/UserActions";
import { logout } from "../store/user/UserSlice";
import HoveringCartTotal from "./HoveringCartTotal";
import HoveringLoginRegister from "./HoveringLoginRegister";
import HoveringSearchBar from "./HoveringSearchBar";

const NavBar = () => {
  const navigate = useNavigate();

  const location = useLocation();

  const dispatch = useDispatch();

  const { isAuthenticated, user, error } = useSelector((state) => state.user);

  const { shopping_cart } = useSelector((state) => state.cart);

  const { wishlist } = useSelector((state) => state.wishlist);

  const urlParams = new URLSearchParams(location.search);

  const [search, setSearch] = useState("");

  const [scrolled, setScrolled] = useState(false);

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const { email, password } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 115) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  function handleSearch(e) {
    e.preventDefault();
    if (location.pathname === "/") {
      navigate(`/catalog/?q=${search}`);
    } else {
      urlParams.set("q", search);
      navigate(`?${urlParams.toString()}`);
    }
  }

  async function signInUser(e) {
    e.preventDefault();
    dispatch(loginUser(formData));
  }

  return (
    <>
      <Navbar
        expand="sm"
        className={
          scrolled ? "bg-body-tertiary fixed_navbar" : "bg-body-tertiary"
        }
      >
        <Container fluid>
          <Link to={"/"} className="navbar-brand">
            <img src={logo} alt="logo" />
          </Link>
          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-sm`} />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-sm`}
            aria-labelledby={`offcanvasNavbarLabel-expand-sm`}
            placement="end"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-sm`}>
                Menu
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="flex-grow-1 pe-3 justify-content-around">
                <Form className="d-flex" onSubmit={(e) => handleSearch(e)}>
                  <InputGroup>
                    <Form.Control
                      type="search"
                      placeholder="Browse the catalog..."
                      aria-label="Search"
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <InputGroup.Text>
                      <Button variant="outline-success" type="submit">
                        {/* <Search size={22} color="black" fontWeight="bold" /> */}
                        SEARCH
                      </Button>
                    </InputGroup.Text>
                  </InputGroup>
                </Form>
                <div className="navSideActions">
                  <Nav.Link className="nav_contact">
                    <Headset size={40} />
                    <div style={{ textAlign: "left", marginLeft: "8px" }}>
                      Call us 24/7 <br />
                      <b>(0706) 803-305</b>
                    </div>
                  </Nav.Link>
                  <NavDropdown
                    title={<Person size={25} />}
                    id={`offcanvasNavbarDropdown-expand-sm`}
                    className="custom_navbar_dropdown"
                  >
                    {isAuthenticated ? (
                      <>
                        <NavDropdown.ItemText>
                          <Link to={"/profile"}>{user?.email}</Link>
                        </NavDropdown.ItemText>
                        <NavDropdown.ItemText>
                          {/* <Link to={"#"}>My Orders</Link> */}
                        </NavDropdown.ItemText>
                        <NavDropdown.Divider />
                        <NavDropdown.ItemText>
                          <Button
                            onClick={() => {
                              // dispatch(logout());
                              dispatch(logoutUser());
                              navigate("/");
                            }}
                          >
                            LOG OUT
                          </Button>
                        </NavDropdown.ItemText>
                      </>
                    ) : (
                      <>
                        <Form onSubmit={(e) => signInUser(e)}>
                          {error && (
                            <NavDropdown.ItemText style={{ color: "red" }}>
                              Validation Error!
                            </NavDropdown.ItemText>
                          )}
                          <NavDropdown.ItemText>
                            <FormControl
                              type="text"
                              name="email"
                              placeholder="Email"
                              value={email}
                              required
                              onChange={(e) => onChange(e)}
                            />
                          </NavDropdown.ItemText>
                          <NavDropdown.ItemText>
                            <FormControl
                              type="password"
                              name="password"
                              placeholder="Password"
                              value={password}
                              required
                              onChange={(e) => onChange(e)}
                            />
                          </NavDropdown.ItemText>

                          <NavDropdown.ItemText>
                            <Button type="submit">SIGN IN</Button>
                          </NavDropdown.ItemText>
                        </Form>
                        <NavDropdown.Divider />
                        <NavDropdown.ItemText>
                          <Link to={"/signup"}>No Account? Sign Up</Link>
                        </NavDropdown.ItemText>
                      </>
                    )}
                  </NavDropdown>

                  <Nav.Link href="/wishlist">
                    <Heart size={25} />
                    {(wishlist || []).length > 0 && (
                      <Badge bg="danger">{(wishlist || []).length}</Badge>
                    )}
                  </Nav.Link>
                  <Nav.Link href="/cart">
                    <Basket3 size={25} />
                    {(shopping_cart || []).length > 0 && (
                      <Badge bg="danger">{(shopping_cart || []).length}</Badge>
                    )}
                  </Nav.Link>
                </div>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
      <div className="phone_actions">
        <HoveringLoginRegister />
        {!location.pathname.startsWith("/admin") && <HoveringSearchBar />}
        {!location.pathname.startsWith("/admin") && <HoveringCartTotal />}
      </div>
    </>
  );
};

export default NavBar;
