import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import WishlistProduct from "./WishlistProduct";

const WishlistPage = () => {
  const { wishlist } = useSelector((state) => state.wishlist);

  const { isAuthenticated } = useSelector((state) => state.user);

  return (
    <div className="wishlistPage">
      <div className="followLinks">
        <Link to={"/"}>Home </Link>/ <Link to={`/wishlist/`}>wishlist </Link>
      </div>
      <br />
      <h2 style={{ fontWeight: "bold" }}>Wish List</h2>
      <br />
      <Container className="custom_container">
        <Row>
          <Col sm={12} className="popular_content ">
            {!isAuthenticated && <h5>Login to see your liked products.</h5>}
            {(wishlist ?? []).map((wishlist_item, index) => {
              return (
                <WishlistProduct
                  key={index}
                  product_id={wishlist_item?.product}
                />
              );
            })}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default WishlistPage;
