import React, { useEffect, useState } from "react";
import { Button, Dropdown, Form } from "react-bootstrap";
import { Dash } from "react-bootstrap-icons";
import { useSelector } from "react-redux";

const PCategoryForm = ({ product, setProduct }) => {
  const categoriesList = useSelector((state) => state.data.categories || []);

  const [prod_categ_list, setProdCategories] = useState([]);

  useEffect(() => {
    setProdCategories(product.categories);
  }, [product.categories]);

  return (
    <Form.Group className="mb-3">
      <Form.Label>Categories </Form.Label>
      <div className="form-control-in-div">
        <Form.Group>
          <Form.Control
            type="text"
            required
            value={
              product.categories && product.categories.length >= 1
                ? "filled"
                : ""
            }
            name="categ"
            className="input_type_hidden"
            onChange={() => {}}
          />
          <Form.Control.Feedback type="invalid">
            Select At Least One Category
          </Form.Control.Feedback>
        </Form.Group>
        <div className="toggle_list_div">
          {product.categories.map((catg, index) => {
            return (
              <Button
                key={index}
                variant="outline-secondary"
                className="toggle_list_btn"
                onClick={() => {
                  setProduct({
                    ...product,
                    categories: prod_categ_list.slice(0, -1),
                  });
                }}
                disabled={index !== product.categories.length - 1}
              >
                {catg.name} <Dash size={25} />
              </Button>
            );
          })}
        </div>
        <Dropdown>
          <Dropdown.Toggle id="dropdown-basic">
            {prod_categ_list.length >= 1 && prod_categ_list.slice(-1)[0].name}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {prod_categ_list.length >= 1
              ? (
                  (
                    categoriesList.find((c) => {
                      return c.id === prod_categ_list.slice(-1)[0].id;
                    }) ?? []
                  ).sub_categories ?? []
                ).map((categ, index) => {
                  return (
                    <Dropdown.Item
                      key={index}
                      onClick={() => {
                        setProduct({
                          ...product,
                          categories: [...prod_categ_list, { ...categ }],
                        });
                      }}
                    >
                      {categ.name}
                    </Dropdown.Item>
                  );
                })
              : categoriesList
                  .filter((c) => c.top_level)
                  .map((categ, index) => {
                    return (
                      <Dropdown.Item
                        key={index}
                        onClick={() => {
                          setProduct({
                            ...product,
                            categories: [...prod_categ_list, { ...categ }],
                          });
                        }}
                      >
                        {categ.name}
                      </Dropdown.Item>
                    );
                  })}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </Form.Group>
  );
};

export default PCategoryForm;
