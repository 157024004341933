import React, { useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { Dash, Plus } from "react-bootstrap-icons";

const PMetaDataForm = ({ product, setProduct }) => {
  const [typedColor, setTypedColor] = useState("");

  const [seats, setSeats] = useState("");

  const [vCompatibility, setVCompatibility] = useState("");

  const [partNumber, setPartNumber] = useState("");

  const [material, setMaterial] = useState("");

  const [warranty, setWarranty] = useState("");

  const [countryOrigin, setCountryOrigin] = useState("");

  const [condition, setCondition] = useState("");

  const [typedYear, setTypedYear] = useState(null);

  const [typedMetaDataKey, setTypedMetaDataKey] = useState("");

  const [typedMetaDataValue, setTypedMetaDataValue] = useState("");

  function removeItem(index, slist) {
    slist.splice(index, 1);
    return slist;
  }

  return (
    <>
      <Form.Group className="mb-3">
        <Form.Label>Material (Opt.)</Form.Label>
        <div className="form-control-in-div">
          <InputGroup>
            <Form.Control
              type="search"
              placeholder="..."
              className="me-2 no_validation_needed"
              aria-label="Search"
              aria-describedby="color-1"
              value={material}
              onChange={(e) => setMaterial(e.target.value)}
            />
            <InputGroup.Text id="color-1">
              <Button
                variant="outline-secondary"
                onClick={() => {
                  if (material && material.length > 0) {
                    setProduct({
                      ...product,
                      meta_data: [
                        ...product.meta_data,
                        { key: "Material", value: material },
                      ],
                    });
                  }
                }}
              >
                <Plus size={25} />
              </Button>
            </InputGroup.Text>
          </InputGroup>
        </div>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Condition (Opt.)</Form.Label>
        <div className="form-control-in-div">
          <InputGroup>
            <Form.Control
              type="search"
              placeholder="..."
              className="me-2 no_validation_needed"
              aria-label="Search"
              aria-describedby="color-1"
              value={condition}
              onChange={(e) => setCondition(e.target.value)}
            />
            <InputGroup.Text id="color-1">
              <Button
                variant="outline-secondary"
                onClick={() => {
                  if (condition && condition.length > 0) {
                    setProduct({
                      ...product,
                      meta_data: [
                        ...product.meta_data,
                        { key: "Condition", value: condition },
                      ],
                    });
                  }
                }}
              >
                <Plus size={25} />
              </Button>
            </InputGroup.Text>
          </InputGroup>
        </div>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Number of seats(Opt.)</Form.Label>
        <div className="form-control-in-div">
          <InputGroup>
            <Form.Control
              type="number"
              placeholder="..."
              className="me-2 no_validation_needed"
              aria-label="Search"
              aria-describedby="color-1"
              value={seats}
              onChange={(e) => setSeats(e.target.value)}
            />
            <InputGroup.Text id="color-1">
              <Button
                variant="outline-secondary"
                onClick={() => {
                  if (seats && seats.length > 0) {
                    setProduct({
                      ...product,
                      meta_data: [
                        ...product.meta_data,
                        { key: "seats", value: seats },
                      ],
                    });
                  }
                }}
              >
                <Plus size={25} />
              </Button>
            </InputGroup.Text>
          </InputGroup>
        </div>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Color (Opt.)</Form.Label>
        <div className="form-control-in-div">
          <InputGroup>
            <Form.Control
              type="search"
              placeholder="..."
              className="me-2 no_validation_needed"
              aria-label="Search"
              aria-describedby="color-1"
              value={typedColor}
              onChange={(e) => setTypedColor(e.target.value)}
            />
            <InputGroup.Text id="color-1">
              <Button
                variant="outline-secondary"
                onClick={() => {
                  if (typedColor && typedColor.length > 2) {
                    setProduct({
                      ...product,
                      meta_data: [
                        ...product.meta_data,
                        { key: "color", value: typedColor },
                      ],
                    });
                    setTypedColor("");
                  }
                }}
              >
                <Plus size={25} />
              </Button>
            </InputGroup.Text>
          </InputGroup>
        </div>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Add Any Other Meta Data (Opt.)</Form.Label>

        <div className="form-control-in-div">
          {product.meta_data &&
            product.meta_data.map((mt, index) => {
              return (
                <div key={index} className="two-inputs">
                  <div>
                    <Form.Group className="mb-3">
                      <Form.Label>Key </Form.Label>
                      <Form.Control
                        type="text"
                        value={mt.key}
                        className="no_validation_needed"
                        disabled
                      />
                    </Form.Group>
                  </div>
                  <div>
                    <Form.Group className="mb-3">
                      <Form.Label>Value </Form.Label>
                      <Form.Control
                        type="text"
                        value={mt.value}
                        className="no_validation_needed"
                        disabled
                      />
                    </Form.Group>
                  </div>
                  <Button
                    variant="outline-secondary"
                    key={index}
                    onClick={() => {
                      setProduct({
                        ...product,
                        meta_data: removeItem(index, product.meta_data),
                      });
                    }}
                  >
                    <Dash size={25} />
                  </Button>
                </div>
              );
            })}
          <div className="two-inputs">
            <div>
              <Form.Group className="mb-3">
                <Form.Label>Key </Form.Label>
                <Form.Control
                  type="text"
                  className="no_validation_needed"
                  placeholder="Atleast 2 characters..."
                  value={typedMetaDataKey}
                  onChange={(e) => setTypedMetaDataKey(e.target.value)}
                />
              </Form.Group>
            </div>
            <div>
              <Form.Group className="mb-3">
                <Form.Label>Value </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Atleast 1 character.."
                  className="no_validation_needed"
                  value={typedMetaDataValue}
                  onChange={(e) => setTypedMetaDataValue(e.target.value)}
                />
              </Form.Group>
            </div>
            <Button
              variant="outline-secondary"
              onClick={() => {
                if (
                  typedMetaDataKey.length > 1 &&
                  typedMetaDataValue.length > 0
                ) {
                  setProduct({
                    ...product,
                    meta_data: [
                      ...product.meta_data,
                      { key: typedMetaDataKey, value: typedMetaDataValue },
                    ],
                  });
                  setTypedMetaDataKey("");
                  setTypedMetaDataValue("");
                }
              }}
            >
              <Plus size={25} />
            </Button>
          </div>
        </div>
      </Form.Group>
    </>
  );
};

export default PMetaDataForm;
