import React, { useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { Dash, Plus } from "react-bootstrap-icons";

const PTagsForm = ({ product, setProduct }) => {
  const [typedTag, setTypedTag] = useState("");

  function removeItem(index, slist) {
    slist.splice(index, 1);
    return slist;
  }

  function addItem(str, slist) {
    slist.push(str);
    let newList = [];
    slist.forEach((element) => {
      if (element.length > 1) {
        newList.push(element);
      }
    });
    return newList;
  }

  return (
    <Form.Group className="mb-3">
      <Form.Label>Tags/Keywords/Search Terms (Opt.) </Form.Label>
      <div className="form-control-in-div">
        <div className="toggle_list_div">
          {product.tags &&
            product.tags.split(",").map((str, index) => {
              return (
                <Button
                  key={index}
                  variant="outline-secondary"
                  className="toggle_list_btn"
                  onClick={() => {
                    setProduct({
                      ...product,
                      tags: removeItem(index, product.tags.split(",")).join(),
                    });
                  }}
                >
                  {str} <Dash size={25} />
                </Button>
              );
            })}
        </div>
        <InputGroup>
          <Form.Control
            type="search"
            placeholder="Add keyword..."
            className="me-2 no_validation_needed"
            aria-label="Search"
            aria-describedby="tags-1"
            value={typedTag}
            onChange={(e) => setTypedTag(e.target.value)}
          />
          <InputGroup.Text id="tags-1">
            <Button
              variant="outline-secondary"
              onClick={() => {
                if (typedTag && typedTag.length > 1) {
                  setProduct({
                    ...product,
                    tags: addItem(
                      typedTag,
                      (product.tags ?? "").split(",")
                    ).join(),
                  });
                  setTypedTag("");
                }
              }}
            >
              <Plus size={25} />
            </Button>
          </InputGroup.Text>
        </InputGroup>
      </div>
    </Form.Group>
  );
};

export default PTagsForm;
