import axios from "axios";
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";

const OrderLineItem = ({ item, index }) => {
  const [product, setProduct] = useState(null);

  async function fetchProduct() {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/product/${item.product}/`)
      .catch((_) => {})
      .then((response) => {
        if (response && response.status === 200) {
          setProduct(response.data);
        }
      });
  }

  useEffect(() => {
    fetchProduct();
    // eslint-disable-next-line
  }, [item]);
  return (
    <Table>
      <tbody>
        <tr>
          <td>#</td>
          <td> {index}</td>
        </tr>
        <tr>
          <td>Name</td>
          <td> {product?.name}</td>
        </tr>
        <tr>
          <td>Price</td>
          <td> {item?.price}</td>
        </tr>
        <tr>
          <td>Quantity</td>
          <td> {item?.quantity}</td>
        </tr>
        <tr>
          <td>Total</td>
          <td> {item?.total}</td>
        </tr>
      </tbody>
    </Table>
  );
};

export default OrderLineItem;
