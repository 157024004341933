import React, { useEffect, useState } from "react";
import { Alert, Spinner, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import AdminPage from "./AdminPage";
import axios from "axios";
import { X } from "react-bootstrap-icons";
import { basicConfig } from "../../constants";

const AdminListDeals = () => {
  const [loading, setLoading] = useState(true);

  const [showError, setShowError] = useState(false);

  const [deals, setDeals] = useState([]);

  async function fetchDeals() {
    setLoading(true);

    await axios
      .get(`${process.env.REACT_APP_API_URL}/deals/`)
      .catch((_) => {})
      .then((response) => {
        if (response && response.status === 200) {
          setDeals(response.data);
        }
      });
    setLoading(false);
  }

  useEffect(() => {
    fetchDeals();
  }, []);

  async function onRemove(id) {
    // change featured to false
    await axios
      .delete(`${process.env.REACT_APP_API_URL}/deals/${id}`, basicConfig)
      .catch((_) => {})
      .then((response) => {
        if (response && response.status === 204) {
          fetchDeals();
        }
      });
  }

  return (
    <AdminPage>
      {showError && (
        <Alert
          variant="danger"
          onClose={() => {
            setShowError(false);
          }}
          dismissible
        >
          <Alert.Heading>Error fetching.</Alert.Heading>
        </Alert>
      )}
      {loading && <Spinner animation="grow" />}
      <div className="admin_content">
        <div className="admin_content_header">
          <h4>Deals Of The Week</h4>
        </div>

        <div className="admin_content_content">
          <Table striped hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Product Id</th>
                <th>Name</th>
                <th>...</th>
              </tr>
            </thead>
            <tbody>
              {deals.map((deal, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{deal?.product?.id}</td>
                    <td>
                      <Link to={`/admin/products/change/${deal?.product?.id}/`}>
                        {deal?.product?.name}
                      </Link>
                    </td>
                    <td>
                      <X onClick={() => onRemove(deal?.id)} />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
    </AdminPage>
  );
};

export default AdminListDeals;
