import React from "react";
import { Col, Container, Dropdown, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import AdminLogin from "./forms/AdminLoginForm";
import Sidebar from "../../components/Sidebar";
import { Link } from "react-router-dom";

const AdminPage = ({ children }) => {
  const { is_staff } = useSelector((state) => state.user?.user || false);

  const pages = [
    {
      title: "Products",
      link: "/admin/products/",
    },
    {
      title: "Popular Products",
      link: "/admin/popular-products/",
    },
    {
      title: "Deals of The Week",
      link: "/admin/deals/",
    },
    {
      title: "Categories",
      link: "/admin/categories/",
    },
    {
      title: "Sub Categories",
      link: "/admin/sub-categories/",
    },
    {
      title: "Orders",
      link: "/admin/orders/",
    },
  ];

  return (
    <>
      {is_staff ? (
        <div className="adminPage">
          <Container className="custom_container">
            <Row>
              <Col sm={3} style={{ textAlign: "left" }}>
                <Dropdown className="toggle_siderbar">
                  <Dropdown.Toggle id="dropdown-basic">Menu</Dropdown.Toggle>

                  <Dropdown.Menu>
                    {pages.map((item, i) => {
                      return (
                        <Dropdown.Item key={i}>
                          <Link to={item.link} className="nav-link">
                            {item.title}
                          </Link>
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
                <Sidebar links={pages} />
              </Col>
              <Col sm={9}>
                {children ?? (
                  <center>
                    <h3 style={{ fontWeight: "300" }}>Select a table</h3>{" "}
                  </center>
                )}
              </Col>
            </Row>
          </Container>
        </div>
      ) : (
        <AdminLogin />
      )}
    </>
  );
};

export default AdminPage;
