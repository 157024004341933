import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";

const PImageForm = ({
  product,
  setProduct,
  imagesToDelete,
  setImagesToDelete,
}) => {
  const [uploadError, setUploadError] = useState(false);
  const [uploadErrorMsg, setUploadErrorMsg] = useState("");

  function handleFileUpload(e) {
    setUploadError(false);
    setUploadErrorMsg("");
    if (e.target.files) {
      let file = e.target.files[0];
      if (file.size < 125000) {
        let ext = file.name.split(".").slice(-1)[0];
        if (
          (ext === "jpg") |
          (ext === "jpeg") |
          (ext === "png") |
          (ext === "gif")
        ) {
          setProduct({
            ...product,
            images: [...product.images, { src: file }],
          });
        } else {
          setUploadError(true);
          setUploadErrorMsg("Allowed: .png, .jpg, .jpeg, .gif");
        }
      } else {
        setUploadError(true);
        setUploadErrorMsg("File should be less than 100kb");
      }
    }
  }

  function removeItem(index, slist) {
    slist.splice(index, 1);
    return slist;
  }
  return (
    <Form.Group className="mb-3">
      <div className="form-control-in-div" style={{ width: "100%" }}>
        <Form.Group>
          <Form.Control
            type="text"
            required
            value={product.images && product.images.length >= 1 ? "filled" : ""}
            name="brand"
            readOnly
            className="input_type_hidden"
          />
          <Form.Control.Feedback type="invalid">
            Add an image
          </Form.Control.Feedback>
        </Form.Group>
        <div className="toggle_list_div" style={{ width: "100%" }}>
          {product.images.map((image, index) => {
            return (
              <div key={index} className="form-image-display">
                <img
                  src={
                    typeof image?.src === "string"
                      ? `${process.env.REACT_APP_API_URL}${image?.src}`
                      : URL.createObjectURL(image?.src)
                  }
                  name={`Image-${index}`}
                  alt={image.alt ? image.alt : ""}
                />
                <div className="form-image-buttons">
                  <Button
                    variant="link"
                    onClick={() => {
                      if (image?.id) {
                        setImagesToDelete([...imagesToDelete, image.id]);
                      }

                      setProduct({
                        ...product,
                        images: removeItem(index, product.images),
                      });
                    }}
                  >
                    Delete
                  </Button>
                </div>
              </div>
            );
          })}
        </div>

        <span className="btn btn-primary btn-file">
          Add Image
          <input
            type="file"
            name="prod-image"
            className="form-control"
            accept="image/png, image/jpeg"
            onChange={(e) => handleFileUpload(e)}
          />
        </span>
        {uploadError ? <i style={{ color: "red" }}>{uploadErrorMsg}</i> : <></>}
      </div>
    </Form.Group>
  );
};

export default PImageForm;
