export function localizeDate(param) {
  // for text display
  // 2024-08-22T15:49
  try {
    let t = new Date(param).toLocaleDateString(undefined, {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
      weekday: "short",
      hour: "2-digit",
      hour12: true,
      minute: "2-digit",
    });

    return t;
  } catch (_) {
    return "";
  }
}

export function gmtDate(param) {
  // to send to db
  // 2024-08-22T15:49
  try {
    let d = new Date(param);
    var datestring =
      d.getUTCFullYear() +
      "-" +
      ("0" + (d.getUTCMonth() + 1)).slice(-2) +
      "-" +
      ("0" + d.getUTCDate()).slice(-2) +
      "T" +
      ("0" + d.getUTCHours()).slice(-2) +
      ":" +
      ("0" + d.getUTCMinutes()).slice(-2) +
      ":" +
      ("0" + d.getUTCSeconds()).slice(-2) +
      "Z";

    return datestring;
  } catch (_) {
    return "";
  }
}

export function valuefyDate(param) {
  // for input display, automatically converts to local time
  if (param != null) {
    try {
      let d = new Date(param);

      var datestring =
        d.getFullYear() +
        "-" +
        ("0" + (d.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + d.getDate()).slice(-2) +
        "T" +
        ("0" + d.getHours()).slice(-2) +
        ":" +
        ("0" + d.getMinutes()).slice(-2) +
        ":" +
        ("0" + d.getSeconds()).slice(-2);
      return datestring;
    } catch (_) {
      return "";
    }
  }
  return "";
}
