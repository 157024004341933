import React from "react";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet-async";

const AboutUs = () => {
  return (
    <>
      <Helmet>
        <title>About Us</title>
        <meta name="description" content="Welcome to Thika Road Furnitures." />
        <link rel="canonical" href="/about-us" />
      </Helmet>
      <div className="about-us">
        <Container>
          <h1>Thika Road Furnitures: Crafting Dream Homes</h1>
          In today's fast-paced world, finding a perfect balance between style
          and comfort in home furnishings can be quite a challenge. Thika Road
          Furnitures, an online home decor and furniture store, stands out by
          making this balance attainable for everyone.
          <p></p>
          We take pride in offering an extensive collection of furniture that
          caters to diverse tastes and budgets. Our commitment to affordability
          does not compromise quality; each piece is designed to be durable,
          stylish, and functional. Whether you're furnishing a new home or
          updating your current space, our vast selection ensures that you find
          the perfect items that fit your style and needs.
          <p></p>
          <h5> Exceptional Customer Service</h5>
          <p></p>
          Customer service is at the heart of our operations. From the moment
          you visit our website to the final delivery of your furniture, we
          strive to provide a seamless and enjoyable shopping experience. We
          believe that building strong relationships with our customers is
          essential to our success, and we go above and beyond to ensure your
          satisfaction.
          <p></p>
          <h5>Unique Items</h5>
          <p></p>
          What sets us apart is our unique collection of items. These pieces are
          perfect for adding character and personality to your home, making it
          warm and welcoming. Our rustic furniture features natural materials,
          earthy tones, and handcrafted details that bring a sense of
          tranquility and charm to any space. On the other hand, our retro items
          boast bold colors, geometric patterns, and vintage designs that add a
          touch of nostalgia and flair. Whether you're looking for a statement
          piece to anchor a room or subtle accents to complement your decor, our
          collections have something for everyone.
          <p></p>
          <h5>Creating Warm and Welcoming Spaces</h5>
          Home is where the heart is, and Thika Road Furnitures understands the
          importance of creating spaces that are not only functional but also
          inviting. Our furniture is designed to make your home a sanctuary
          where you can relax, unwind, and enjoy life with family and friends.
          From cozy sofas and comfortable beds to stylish dining sets and
          elegant storage solutions, we offer everything you need to transform
          your house into a home.
          <p></p>
          <h5> Office Items</h5>
          In addition to our home furniture, Thika Road Furnitures also offers a
          range of office desks and chairs. With the rise of remote work, having
          a dedicated and comfortable workspace at home has become more
          important than ever. Our office furniture is designed to be both
          stylish and practical, helping you create a productive environment
          that inspires creativity and focus.
          <p></p>
          Explore our collections today and start creating the home of your
          dreams.
        </Container>
      </div>
    </>
  );
};

export default AboutUs;
