import Carousel from "react-bootstrap/Carousel";
import React, { useState } from "react";
import image1 from "../../assets/images/image1.jpg";
import image7 from "../../assets/images/image7.jpg";
import image8 from "../../assets/images/image8.jpg";
import image4 from "../../assets/images/image4.jpg";
import CategoriesCarousel from "./CategoriesCarousel";
import { Link } from "react-router-dom";

const Banner = () => {
  const sliding_list = [
    {
      title: "Sofas & Sectionals",
      image: image1,
      desc: "Discover cozy, affordable sofas for ultimate comfort. Perfect for relaxing with family. 🛋️",
      link: "/product-category/Sofas & Sectionals/",
    },
    {
      title: "Beds",
      image: image7,
      desc: "Affordable beds for all styles and budgets. Sleep in comfort and style with our vast selection. 🛏️",
      link: "/product-category/Beds/",
    },
    {
      title: "TV & Media Furniture",
      image: image8,
      desc: "Cut the clutter and manage cables with our stylish, functional TV stands and cabinets. 📺",
      link: "/product-category/TV & Media Furniture/",
    },
    {
      title: "Tables & Desks",
      image: image4,
      desc: "Versatile desks and tables for every space and activity. Perfect for family, work, and play. 📚",
      link: "/product-category/Tables & Desks/",
    },
  ];

  const [mouseLeave, setMouseLeave] = useState(false);

  return (
    <div className="banner">
      <div
        className="categories_carousel"
        onMouseLeave={() => setMouseLeave(true)}
      >
        <CategoriesCarousel
          mouseLeave={mouseLeave}
          setMouseLeave={setMouseLeave}
        />
      </div>
      <div className="main_carousel">
        <Carousel fade indicators={false}>
          {sliding_list.map((item, index) => {
            return (
              <Carousel.Item key={index}>
                <div className="image_overlay">
                  <img src={item.image} className="d-block w-100" alt="" />
                </div>
                <Carousel.Caption>
                  <h2>{item.title}</h2>
                  <p>{item.desc}</p>
                  <Link to={item.link} className="btn">
                    Shop Now
                  </Link>
                </Carousel.Caption>
              </Carousel.Item>
            );
          })}
        </Carousel>
      </div>
    </div>
  );
};

export default Banner;
