import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { Dash, Plus } from "react-bootstrap-icons";
import { basicConfig } from "../../../../constants";

const M2MRelation = ({ title, l_ist, onSelect, onRemove }) => {
  const [text, setText] = useState("");
  const [pList, setPList] = useState([]);

  useEffect(() => {
    async function fetchList() {
      if (text.length < 1) {
        setPList([]);
      } else {
        await axios
          .post(
            `${process.env.REACT_APP_API_URL}/filter/`,
            {
              q_search: text,
              limit: 5,
            },
            basicConfig
          )
          .catch((_) => {})
          .then((response) => {
            if (response && response.status === 200) {
              setPList(response.data?.products);
            }
          });
      }
    }
    fetchList();
  }, [text]);

  return (
    <Form.Group className="mb-3">
      <Form.Label>{title}</Form.Label>
      <div className="form-control-in-div">
        <div className="toggle_list_div">
          {l_ist.map((elem, index) => {
            return (
              <Button
                key={index}
                variant="outline-secondary"
                className="toggle_list_btn"
                onClick={() => onRemove(index)}
              >
                {elem.name} <Dash size={25} />
              </Button>
            );
          })}
        </div>
        <InputGroup>
          <Form.Control
            type="search"
            placeholder="Search product..."
            className="me-2 no_validation_needed"
            aria-label="Search"
            aria-describedby="related-prod"
            value={text}
            onChange={(e) => setText(e.target.value)}
          />
          <InputGroup.Text id="related-prod">
            <Button variant="outline-secondary">
              <Plus size={25} />
            </Button>
          </InputGroup.Text>
        </InputGroup>
        <ul style={{ listStyle: "none", margin: "2px", padding: "0" }}>
          {pList.map((elem, index) => {
            return (
              <li
                key={index}
                onClick={() => {
                  onSelect(elem);
                  setText("");
                  setPList([]);
                }}
              >
                {elem?.name}
              </li>
            );
          })}
        </ul>
      </div>
    </Form.Group>
  );
};

export default M2MRelation;
