import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import AdminPage from "./AdminPage";
import {
  Alert,
  Badge,
  Col,
  Dropdown,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import MainSearch from "../../components/MainSearch";
import PagePagination from "../../components/PagePagination";
import { ThreeDots } from "react-bootstrap-icons";
import axios from "axios";
import { basicConfig, basicConfigMultipart } from "../../constants";
import { gmtDate, localizeDate } from "../../common_methods/formatDate";
import { isArray, return_ids_from_objects } from "../../CommonMethods";

const per_page_limit = 10;

const AdminListOrders = () => {
  const location = useLocation();

  const urlParams = new URLSearchParams(location.search);

  const search = urlParams.get("q");

  const page = urlParams.get("page") || 1;

  const [ordersCount, setordersCount] = useState(1);

  const [pages, setPages] = useState(1);

  const [loading, setLoading] = useState(true);

  const [showError, setShowError] = useState(false);

  const [errorMsg, setErrorMsg] = useState("");

  const [ordersList, setordersList] = useState([]);

  const fetchorders = async (search, per_page_limit) => {
    setShowError(false);
    setLoading(true);

    let formData = new FormData();

    formData.append("limit", per_page_limit);
    formData.append("page", page);

    if (search && search.trim().length > 0) {
      formData.append("q_search", search);
    }

    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/orders/filter/`,
        formData,
        basicConfig
      )
      .catch((_) => {
        setShowError(true);
        setErrorMsg("Error Fetching!");
      })
      .then((response) => {
        if (response && response.status === 200) {
          setordersCount(response.data["count"]);
          setordersList(response.data["orders"]);
        } else {
          setErrorMsg("Error Fetching!");
          setShowError(true);
        }
      });

    setLoading(false);
  };

  useEffect(() => {
    fetchorders(search, per_page_limit);
    // eslint-disable-next-line
  }, [page, search]);

  useEffect(() => {
    setPages(Math.ceil(ordersCount / per_page_limit));
  }, [ordersCount]);

  const alterOrder = async (id, body) => {
    setLoading(true);
    setShowError(false);

    let modified_order = {
      ...body,
      needs_payment: body?.status === "pending",
    };

    let line_items_ids = [];

    for (const lineItem of body.line_items) {
      const id = await return_ids_from_objects(lineItem, ``, false);
      if (id !== null) {
        line_items_ids.push(id);
      }
    }

    modified_order.line_items = line_items_ids;

    modified_order.billing = modified_order?.billing?.id ?? null;

    modified_order.shipping = modified_order?.shipping?.id ?? null;

    const formData = new FormData();

    for (const [key, value] of Object.entries(modified_order)) {
      if (value != null) {
        if (isArray(value)) {
          value.forEach((i) => {
            formData.append(`${key}`, i);
          });
        } else {
          formData.append(`${key}`, value);
        }
      }
    }

    await axios
      .put(
        `${process.env.REACT_APP_API_URL}/orders/order/${id}/`,
        formData,
        basicConfigMultipart
      )
      .catch((_) => {
        setErrorMsg("Error updating!");
        setShowError(true);
      })
      .then((response) => {
        if (response && response.status === 200) {
          fetchorders(search, per_page_limit);
        } else {
          setErrorMsg("Error updating!");
          setShowError(true);
        }
      });
    setLoading(false);
  };

  const completeOrder = async (id, order) => {
    let body = {
      ...order,
      status: "completed",
      date_paid_gmt: gmtDate(new Date().toLocaleString()),
      date_completed_gmt: gmtDate(new Date().toLocaleString()),
      needs_payment: false,
    };
    await alterOrder(id, body);
  };

  const cancelOrder = async (id, order) => {
    let body = {
      ...order,
      status: "cancelled",
      date_paid_gmt: null,
      date_completed_gmt: null,
      needs_payment: false,
    };
    await alterOrder(id, body);
  };

  const trashOrder = async (id, order) => {
    let body = {
      ...order,
      status: "trash",
      date_paid_gmt: null,
      date_completed_gmt: null,
      needs_payment: false,
    };
    await alterOrder(id, body);
  };
  return (
    <AdminPage>
      {showError && (
        <Alert
          variant="danger"
          onClose={() => {
            setShowError(false);
          }}
          dismissible
        >
          <Alert.Heading>{errorMsg}</Alert.Heading>
        </Alert>
      )}
      {loading && <Spinner animation="grow" />}
      <div className="admin_content">
        <div className="admin_content_header">
          <h4>Select Order to change</h4>
        </div>
        {/* place a searchbox here */}
        <MainSearch placeholder="Search by email, names, phone number..." />

        <div className="admin_content_content">
          <Row>
            <Col sm={12} style={{ textAlign: "right" }}>
              <Badge bg="danger">Pending</Badge>
              <span> </span>
              <Badge bg="success">Completed</Badge>
            </Col>
          </Row>
          <Table striped hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Date Created</th>
                <th>Customer/Billed To</th>
                <th>Status</th>
                <th>Grand Total</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {ordersList.map((order, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>
                      <Link to={`/admin/orders/change/${order?.id}/`}>
                        {localizeDate(order?.date_created_gmt)}
                      </Link>
                    </td>
                    <td
                      style={{
                        color:
                          order?.status === "pending"
                            ? "red"
                            : order?.status === "completed"
                            ? "green"
                            : "black",
                      }}
                    >
                      {order?.customer ?? `Guest: ${order?.billing?.email}`}
                    </td>
                    <td>{order?.status}</td>
                    <td>{order?.total}</td>
                    <td>
                      <Dropdown>
                        <Dropdown.Toggle id="dropdown-basic">
                          <ThreeDots size={23} />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => {
                              completeOrder(order?.id, order);
                            }}
                          >
                            Complete
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              cancelOrder(order?.id, order);
                            }}
                          >
                            Cancel
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              trashOrder(order?.id, order);
                            }}
                          >
                            Trash
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>

          {/* pagination here */}
          <PagePagination page={parseInt(page) || 1} pages={pages} />
        </div>
      </div>
    </AdminPage>
  );
};

export default AdminListOrders;
