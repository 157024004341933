import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";

const client_reviews = [
  {
    review:
      "Fantastic quality and service! My new sofa arrived promptly and looks amazing. Highly recommend!",
    reviewer: "Gladys Kibet",
  },
  {
    review:
      "I bought a wardrobe, and it fits perfectly in my bedroom. The overall shopping experience was excellent, with helpful customer service and timely delivery. One suggestion would be to include more detailed product photos on the website to help with decision-making.",
    reviewer: "Ogopa",
  },
  {
    review:
      "The coffee table I ordered is exactly what I wanted. Great design and sturdy build.",
    reviewer: "Richard Otis",
  },
  {
    review:
      "I ordered a dining set, and the entire process was smooth from start to finish. The customer service team was incredibly supportive, answering all my queries patiently. The table and chairs are elegant and well-crafted. Overall, a fantastic experience!",
    reviewer: "Jillian Kimani",
  },
  {
    review:
      "I love my new sectional sofa. The quality is top-notch, and delivery was quick. Just wish there were more color options.",
    reviewer: "Wambua",
  },
];

const WhatClientsSay = () => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      let i = index + 1;
      if (i > client_reviews.length - 1) {
        i = 0;
      }
      setIndex(i);
    }, 20000);
    return () => clearInterval(interval);
  }, [index]);
  return (
    <Row className="what_clients_say">
      <h1>What Our Clients Say</h1>
      <p>
        Join our happy 10,000+ customers who are delighted by our exceptional
        service and quality affordable furniture, creating their dream homes
        effortlessly.
      </p>
      <div className="what_clients_say_carousel">
        <blockquote class="blockquote text-right">
          <p>{client_reviews[index].review}</p>
          <footer class="blockquote-footer">
            <cite title="Source Title">{client_reviews[index].reviewer}</cite>
          </footer>
        </blockquote>
      </div>
    </Row>
  );
};

export default WhatClientsSay;
