import React, { useEffect, useState } from "react";
import { Button, Spinner, Table } from "react-bootstrap";
import { Plus } from "react-bootstrap-icons";
import { Link, useLocation } from "react-router-dom";
import AdminPage from "./AdminPage";
import PagePagination from "../../components/PagePagination";
import MainSearch from "../../components/MainSearch";
import { useSelector } from "react-redux";

const per_page_limit = 10;

const AdminListCategories = () => {
  const { categories, loading, error } = useSelector((state) => state.data);

  const location = useLocation();

  const urlParams = new URLSearchParams(location.search);

  const search = urlParams.get("q");

  const page = urlParams.get("page") || 1;

  const [categoriesList_display, setCategoriesListDisplay] = useState([]);

  const [pages, setPages] = useState(1);

  useEffect(() => {
    if (search) {
      setCategoriesListDisplay(
        (categories || []).filter(
          (c) =>
            c.top_level && c.name.toLowerCase().includes(search.toLowerCase())
        )
      );
    } else {
      setCategoriesListDisplay((categories ?? []).filter((c) => c.top_level));
    }
  }, [categories, search]);

  useEffect(() => {
    setPages(Math.ceil(categoriesList_display.length / per_page_limit));
  }, [categoriesList_display]);

  return (
    <AdminPage>
      {error ? (
        <h3 style={{ colors: "red" }}>Network Error!</h3>
      ) : (
        <div className="admin_content">
          <div className="admin_content_header">
            <h4>Select Category to change</h4>
            <Link to={"/admin/categories/add/"}>
              <Button>
                <Plus size={25} /> Add New
              </Button>
            </Link>
          </div>
          {/* place a searchbox here */}

          <MainSearch />

          {loading ? (
            <Spinner animation="grow" />
          ) : (
            <div className="admin_content_content">
              <Table striped hover>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>#id</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  {categoriesList_display
                    .slice((page - 1) * per_page_limit, page * per_page_limit)
                    .map((categ, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{categ.id}</td>
                          <td>
                            <Link to={`/admin/categories/change/${categ.id}`}>
                              {categ.name}
                            </Link>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>

              {/* pagination here */}
              <PagePagination page={parseInt(page) || 1} pages={pages} />
            </div>
          )}
        </div>
      )}
    </AdminPage>
  );
};

export default AdminListCategories;
