import React, { useEffect, useState } from "react";
import MinusPlusContainer from "../../components/MinusPlusContainer";
import { Heart, Whatsapp } from "react-bootstrap-icons";
import { alterCart, deleteCart } from "../../store/cart/CartActions";
import { Button, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import noImage from "../../assets/images/no-image.png";
import { formatCurrency } from "../../common_methods/formatCurrency";
import { addWishlist } from "../../store/wishlist/wishlistAction";

const ProductMainDetails = ({ product }) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { loading, shopping_cart } = useSelector((state) => state.cart);

  const { isAuthenticated } = useSelector((state) => state.user);

  const [percentOff, setPercentOff] = useState(0);

  const [inCartItem, setCartItem] = useState(null);

  const [mediaIndex, setMediaIndex] = useState(0);

  useEffect(() => {
    let r = (shopping_cart || []).find(
      (x) => x.prod_id === parseInt(product.id)
    );
    if (r) {
      setCartItem(r);
    }
  }, [product, shopping_cart]);

  useEffect(() => {
    function calculatePercentOff() {
      let perc = 0;
      if (product) {
        if (product.price && product.regular_price) {
          perc =
            ((product.regular_price - product.price) / product.regular_price) *
            100;
        }
      }

      return perc;
    }
    setPercentOff(calculatePercentOff());
  }, [product]);

  const onChange = (e) => {
    if (parseInt(e.target.value)) {
      if (e.target.value > 0 && product?.backorders_allowed) {
        if (inCartItem && inCartItem.prod_id) {
          dispatch(
            alterCart({
              id: inCartItem.id,
              prod_id: inCartItem.prod_id,
              quantity: parseInt(e.target.value),
              meta_data: inCartItem.meta_data,
            })
          );
        } else {
          dispatch(
            alterCart({
              id: null,
              prod_id: product.id,
              quantity: parseInt(e.target.value),
              meta_data: [],
            })
          );
        }
      } else if (
        e.target.value > 0 &&
        e.target.value <= product?.stock_quantity
      ) {
        if (inCartItem && inCartItem.prod_id) {
          dispatch(
            alterCart({
              id: inCartItem.id,
              prod_id: inCartItem.prod_id,
              quantity: parseInt(e.target.value),
              meta_data: inCartItem.meta_data,
            })
          );
        } else {
          dispatch(
            alterCart({
              id: null,
              prod_id: product.id,
              quantity: parseInt(e.target.value),
              meta_data: [],
            })
          );
        }
      }
    }
  };

  const onAdd = () => {
    if (inCartItem && inCartItem.prod_id) {
      if (product?.backorders_allowed) {
        dispatch(
          alterCart({
            id: inCartItem.id,
            prod_id: inCartItem.prod_id,
            quantity: inCartItem.quantity + 1,
            meta_data: inCartItem.meta_data,
          })
        );
      } else if (inCartItem.quantity + 1 <= product?.stock_quantity) {
        dispatch(
          alterCart({
            id: inCartItem.id,
            prod_id: inCartItem.prod_id,
            quantity: inCartItem.quantity + 1,
            meta_data: inCartItem.meta_data,
          })
        );
      }
    } else {
      dispatch(
        alterCart({
          id: null,
          prod_id: product.id,
          quantity: 1,
          meta_data: [],
        })
      );
    }
  };

  const onMinus = () => {
    if (inCartItem && inCartItem.prod_id) {
      if (inCartItem.quantity > 1) {
        dispatch(
          alterCart({
            id: inCartItem.id,
            prod_id: inCartItem.prod_id,
            quantity: inCartItem.quantity - 1,
            meta_data: inCartItem.meta_data,
          })
        );
      } else {
        dispatch(
          deleteCart({ id: inCartItem.id, prod_id: inCartItem.prod_id })
        );
        setCartItem(null);
      }
    }
  };

  return (
    <Row itemScope itemType="https://schema.org/Product">
      <Col sm={5} data-bs-theme="dark" className="product_page_image">
        {percentOff > 0 && (
          <div className="percantage_off">-{percentOff.toFixed(1)}%</div>
        )}

        <div className="product_image_display">
          {(product?.images ?? []).length > 0 ? (
            <img
              itemProp="image"
              src={
                product?.images[mediaIndex]?.src
                  ? `${process.env.REACT_APP_API_URL}${product?.images[mediaIndex]?.src}`
                  : `${noImage}`
              }
              alt={`${process.env.REACT_APP_API_URL}${
                product?.images[mediaIndex]?.alt ?? ""
              }`}
              title={product?.name}
            />
          ) : (
            <img
              itemProp="image"
              src={noImage}
              alt={product?.name}
              title={product?.name}
            />
          )}
        </div>
        <div className="product_image_indicators">
          {(product?.images ?? []).length > 0 &&
            (product?.images ?? []).map((image, index) => (
              <div
                className="image_indicator"
                key={index}
                onClick={() => setMediaIndex(index)}
              >
                <img
                  itemProp="image"
                  src={
                    image?.src
                      ? `${process.env.REACT_APP_API_URL}${image?.src}`
                      : noImage
                  }
                  alt={product?.name}
                  title={product?.name}
                />
              </div>
            ))}
        </div>
      </Col>
      <Col sm={6} className="product_page_details">
        {/* <p>
          <StarRating value={product?.average_rating} /> Reviews(
          {product?.rating_count})
        </p> */}
        <h1 itemProp="name">{product?.name}</h1>
        <p style={{ display: "flex" }}>
          <h5
            itemProp="offers"
            itemScope
            itemType="https://schema.org/Offer"
            style={{ color: "black", marginRight: "10px", fontWeight: "bold" }}
          >
            <span itemProp="priceCurrency" content="KES">
              Ksh.
            </span>{" "}
            <span itemProp="price" content={product?.price}>
              {formatCurrency(product?.price)}
            </span>
          </h5>

          {percentOff > 0 && (
            <span
              style={{
                color: "grey",
                textDecoration: "line-through red",
                fontSize: "13px",
              }}
            >
              Ksh. {formatCurrency(product?.regular_price)}
            </span>
          )}
        </p>
        {product?.stock_status === "instock" ? (
          <a
            itemProp="availability"
            href="https://schema.org/InStock"
            style={{ display: "none" }}
          >
            #
          </a>
        ) : (
          // <p>
          //   <CheckCircle color="green" /> {product?.stock_quantity} in
          //   stock
          // </p>
          <p style={{ color: "red" }}>Out Of Stock</p>
        )}
        <h5 style={{ fontSize: "14px" }}>Quantity</h5>
        <MinusPlusContainer
          val={inCartItem?.quantity || 0}
          onChange={onChange}
          onAdd={onAdd}
          onMinus={onMinus}
          max={product?.stock_quantity}
          backOrdersAllowed={product?.backorders_allowed}
          disabled={product?.stock_status !== "instock"}
        />
        <div className="product_page_details_buttons">
          <Button
            className="add_to_cart"
            onClick={() =>
              dispatch(
                alterCart({
                  id: null,
                  prod_id: product.id,
                  quantity: 1,
                  meta_data: [],
                })
              )
            }
            disabled={
              loading ||
              inCartItem !== null ||
              product?.stock_status !== "instock"
            }
          >
            {inCartItem !== null ? "ADDED" : "ADD TO CART"}
          </Button>
          <Button className="buy_now">BUY NOW</Button>
        </div>
        <p></p>
        <Link
          to={`https://wa.me/254706803305/?text=${encodeURIComponent(
            "Hello, I would like to purchase \n\nProduct :" +
              `${product.name}` +
              "\nPrice : Ksh " +
              `${formatCurrency(product.price)}` +
              `\nURL : ${encodeURI(
                `https://www.trf.co.ke/shop/${product.name}-${product.id}`
              )}` +
              "/\n\nThank you."
          )}`}
          target="_blank"
        >
          <Whatsapp size={20} color="green" /> Whatsapp Order
        </Link>
        <p></p>
        <Button
          onClick={() =>
            isAuthenticated
              ? dispatch(addWishlist({ prod_id: product?.id }))
              : navigate("/login/")
          }
          variant="outline-secondary"
        >
          <Heart /> Add to wishlist
        </Button>
        <hr />
        {product?.brand?.name && product?.brand?.name !== "Other" && (
          <p className="p_re">
            <b>Manufacturer/Brand:</b> {product?.brand?.name}
          </p>
        )}
        <p className="p_re">
          <b>TAGS:</b> {product?.tags}{" "}
        </p>
        {/* <p className="p_re">
      <b>MODEL:</b> {product?.model}{" "}
    </p> */}
      </Col>
    </Row>
  );
};

export default ProductMainDetails;
