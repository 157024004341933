import React, { useEffect, useState } from "react";
import { Alert, Button, Container, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { validatePhoneNumber } from "../../CommonMethods";
import { checkIsAuthenticated } from "../../store/user/UserActions";
import axios from "axios";
import { basicConfig } from "../../constants";
import LoadingDiv from "../../components/LoadingDiv";

const counties = [
  "Nairobi",
  "Mombasa",
  "Kwale",
  "Kilifi",
  "Tana River",
  "Lamu",
  "Taita/Taveta",
  "Garissa",
  "Wajir",
  "Mandera",
  "Marsabit",
  "Isiolo",
  "Meru",
  "Tharaka-Nithi",
  "Embu",
  "Kitui",
  "Machakos",
  "Makueni",
  "Nyandarua",
  "Nyeri",
  "Kirinyaga",
  "Murang'a",
  "Kiambu",
  "Turkana",
  "West Pokot",
  "Samburu",
  "Trans Nzoia",
  "Uasin Gishu",
  "Elgeyo/Marakwet",
  "Nandi",
  "Baringo",
  "Laikipia",
  "Nakuru",
  "Narok",
  "Kajiado",
  "Kericho",
  "Bomet",
  "Kakamega",
  "Vihiga",
  "Bungoma",
  "Busia",
  "Siaya",
  "Kisumu",
  "Homa Bay",
  "Migori",
  "Kisii",
  "Nyamira",
];

const Profile = () => {
  const { isAuthenticated, user } = useSelector((state) => state.user);

  const [formValidated, setFormValidated] = useState(false);

  const [updating, setUpdating] = useState(false);

  const [error, setError] = useState(false);

  const [success, setSuccess] = useState(false);

  const dispatch = useDispatch();

  const [formData, setFormData] = useState(
    user ?? { email: "", name: "", phone_number: "", address: null }
  );

  const { email, name, phone_number, address } = formData;

  const [isPhoneNumberValid, setPhoneNumberValid] = useState(false);

  useEffect(() => {
    setPhoneNumberValid(validatePhoneNumber(phone_number));
  }, [phone_number]);

  async function updateUserDetails(userDict) {
    await axios
      .put(
        `${process.env.REACT_APP_API_URL}/users/${email}/`,
        userDict,
        basicConfig
      )
      .catch((e) => {
        setError(true);
        window.scrollTo(0, 0);
      })
      .then((response) => {
        if (response && response.status === 200) {
          setSuccess(true);
          window.scrollTo(0, 0);
          dispatch(checkIsAuthenticated());
        }
      });
  }

  async function handleSubmit(e) {
    e.preventDefault();

    setUpdating(true);
    setError(false);
    setSuccess(false);

    const form = e.currentTarget;
    window.scrollTo(0, 0);
    if (form.checkValidity() === false) {
      e.stopPropagation();
      setFormValidated(true);
    } else {
      if (isPhoneNumberValid) {
        let modifiedFormData = { ...formData };

        let id = address?.id;

        if (id == null) {
          // create
          await axios
            .post(
              `${process.env.REACT_APP_API_URL}/users/address/`,
              address,
              basicConfig
            )
            .catch((_) => {
              setError(true);
              window.scrollTo(0, 0);
            })
            .then((response) => {
              if (response && response.status === 200) {
                id = response.data?.id;
                modifiedFormData.address = id;
                updateUserDetails(modifiedFormData);
              }
            });
        } else {
          // update
          await axios
            .put(
              `${process.env.REACT_APP_API_URL}/users/address/${id}/`,
              address,
              basicConfig
            )
            .catch((_) => {
              setError(true);
              window.scrollTo(0, 0);
            })
            .then((response) => {
              if (response && response.status === 200) {
                modifiedFormData.address = id;
                updateUserDetails(modifiedFormData);
              }
            });
        }
      }
    }
    setUpdating(false);
  }

  if (!isAuthenticated) {
    return <Navigate to={"/login"} />;
  }

  return (
    <div className="profile">
      {updating && <LoadingDiv />}
      <Container>
        {(error || success) && (
          <Alert
            variant={success ? "success" : "danger"}
            onClose={() => {
              setError(false);
              setSuccess(false);
            }}
            dismissible
          >
            {window.scrollTo(0, 0)}
            <Alert.Heading>
              {success ? "Successfully Updated." : "Error! Try Again."}{" "}
            </Alert.Heading>
          </Alert>
        )}
        <Form
          noValidate
          validated={formValidated}
          onSubmit={(e) => handleSubmit(e)}
        >
          <h5>User Details</h5>
          <Form.Group className="mb-3">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              required
              name="name"
              value={name}
              onChange={(e) => {
                setFormData({ ...formData, name: e.target.value });
              }}
            />
            <Form.Control.Feedback type="invalid">
              Required
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="text"
              required
              name="email"
              value={email}
              disabled
            />
            <Form.Control.Feedback type="invalid">
              Required
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Phone Number</Form.Label>
            <Form.Control
              type="text"
              name="phone_number"
              className={
                isPhoneNumberValid
                  ? "custom-validate-class-valid"
                  : "me-2  custom-validate-class-invalid"
              }
              isInvalid={!isPhoneNumberValid}
              required
              maxLength={15}
              value={phone_number}
              onChange={(e) => {
                setFormData({ ...formData, phone_number: e.target.value });
              }}
            />
            <Form.Control.Feedback type="invalid">
              Please Enter A Valid Phone Number.
            </Form.Control.Feedback>
          </Form.Group>
          <h5>Address</h5>
          <Form.Group className="mb-3">
            <Form.Label>Specify Address</Form.Label>
            <Form.Control
              type="text"
              name="address"
              required
              value={address?.address ?? ""}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  address: { ...address, address: e.target.value },
                });
              }}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Town / City</Form.Label>
            <Form.Control
              type="text"
              name="town"
              required
              value={address?.town ?? ""}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  address: { ...address, town: e.target.value },
                });
              }}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>County</Form.Label>
            <Form.Select
              value={address?.county ?? ""}
              required
              onChange={(e) =>
                setFormData({
                  ...formData,
                  address: { ...address, county: e.target.value },
                })
              }
            >
              {counties.map((s, index) => {
                return (
                  <option value={s} key={index}>
                    {s}
                  </option>
                );
              })}
            </Form.Select>
            {/* <Form.Control
              type="text"
              name="county"
              required
              value={address?.county ?? ""}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  address: { ...address, county: e.target.value },
                });
              }}
            /> */}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Country</Form.Label>
            <Form.Control
              type="text"
              disabled
              name="country"
              value={address?.country ?? "Kenya"}
              onChange={(e) => {}}
            />
          </Form.Group>

          <div className="update_btn_div">
            <Button
              className="form-submit-btn"
              variant="primary"
              type="submit"
              disabled={updating}
            >
              {updating ? "..." : "UPDATE"}
            </Button>
          </div>
        </Form>
      </Container>
    </div>
  );
};

export default Profile;
