import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { tallyCart } from "../store/cart/CartActions";
import { Basket3 } from "react-bootstrap-icons";
import { Link } from "react-router-dom";

const HoveringCartTotal = () => {
  const { shopping_cart, sub_total } = useSelector((state) => state.cart);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(tallyCart());
    // eslint-disable-next-line
  }, [shopping_cart]);

  return (
    (shopping_cart ?? []).length > 0 && (
      <Link to={"/cart"}>
        <div className="hover_shopping_cart">
          <Basket3 size={23} />
          &nbsp; &nbsp;&nbsp;
          <span>{(shopping_cart ?? []).length}</span>
          &nbsp;
          <span>Item(s)</span>
          &nbsp;
          <span>- Ksh. {sub_total}</span>
        </div>
      </Link>
    )
  );
};

export default HoveringCartTotal;
