import React from "react";

const LoadingProductsContainer = () => {
  return (
    <div className="loading-card">
      <div className="loading-card-image">
        <img alt="" />
      </div>
      <div className="loading-card-content-1">
        <img alt="" />
      </div>
      <div className="loading-card-content-2">
        <img alt="" />
      </div>
    </div>
  );
};

export default LoadingProductsContainer;
