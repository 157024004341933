import { createContext, useState } from "react";

export const FilterContext = createContext();

const FilterContextProvider = (props) => {
  const [minPrice, setMinPrice] = useState(0);

  const [maxPrice, setMaxPrice] = useState(500000);

  const [filterbrands, setBrands] = useState([]);

  const [filtercolors, setColors] = useState([]);

  const [filterseats, setSeats] = useState([]);

  const contextValues = {
    minPrice,
    setMinPrice,
    maxPrice,
    setMaxPrice,
    filterbrands,
    setBrands,
    filtercolors,
    setColors,
    filterseats,
    setSeats,
  };

  return (
    <FilterContext.Provider value={contextValues}>
      {props.children}
    </FilterContext.Provider>
  );
};

export default FilterContextProvider;
