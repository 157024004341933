import React, { useEffect, useState } from "react";
import { Alert, Spinner, Table } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import PagePagination from "../../components/PagePagination";
import AdminPage from "./AdminPage";
import axios from "axios";
import { X } from "react-bootstrap-icons";
import { basicConfig } from "../../constants";

const per_page_limit = 10;

const AdminListFeaturedProducts = () => {
  const location = useLocation();

  const urlParams = new URLSearchParams(location.search);

  const page = urlParams.get("page") || 1;

  const [productsCount, setProductsCount] = useState(1);

  const [pages, setPages] = useState(1);

  const [loading, setLoading] = useState(true);

  const [showError, setShowError] = useState(false);

  const [featuredItems, setFeaturedItems] = useState([]);

  async function fetchFeatured() {
    setLoading(true);

    await axios
      .get(`${process.env.REACT_APP_API_URL}/filter/?featured=1&all=1`)
      .catch((_) => {})
      .then((response) => {
        if (response && response.status === 200) {
          setProductsCount(response.data.length);
          setFeaturedItems(
            response.data.slice(
              (page - 1) * per_page_limit,
              page * per_page_limit
            )
          );
        }
      });
    setLoading(false);
  }

  useEffect(() => {
    fetchFeatured();
  }, [page]);

  useEffect(() => {
    setPages(Math.ceil(productsCount / per_page_limit));
  }, [productsCount]);

  async function onRemove(id) {
    // change featured to false
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/remove_featured/`,
        { id: id },
        basicConfig
      )
      .catch((_) => {})
      .then((response) => {
        if (response && response.status === 200) {
          fetchFeatured();
        }
      });
  }

  return (
    <AdminPage>
      {showError && (
        <Alert
          variant="danger"
          onClose={() => {
            setShowError(false);
          }}
          dismissible
        >
          <Alert.Heading>Error fetching.</Alert.Heading>
        </Alert>
      )}
      {loading && <Spinner animation="grow" />}
      <div className="admin_content">
        <div className="admin_content_header">
          <h4>Popular Products</h4>
        </div>

        <div className="admin_content_content">
          <Table striped hover>
            <thead>
              <tr>
                <th>#</th>
                <th>id</th>
                <th>Name</th>
                <th>...</th>
              </tr>
            </thead>
            <tbody>
              {featuredItems.map((prod, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{prod.id}</td>
                    <td>{prod.name}</td>
                    <td>
                      <X onClick={() => onRemove(prod.id)} />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>

          {/* pagination here */}
          <PagePagination page={parseInt(page) || 1} pages={pages} />
        </div>
      </div>
    </AdminPage>
  );
};

export default AdminListFeaturedProducts;
