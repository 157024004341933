import React, { useEffect, useState } from "react";
import { Trash, X } from "react-bootstrap-icons";
import axios from "axios";
import { useDispatch } from "react-redux";
import { alterCart, deleteCart } from "../../store/cart/CartActions";
import { Link } from "react-router-dom";
import MinusPlusContainer from "../../components/MinusPlusContainer";
import { Col, Row } from "react-bootstrap";
import { formatCurrency } from "../../common_methods/formatCurrency";

const CartContainer = ({ cart_item, alt_display }) => {
  const { id, quantity, prod_id, meta_data } = cart_item;

  const dispatch = useDispatch();

  const [product, setProduct] = useState(null);

  // useEffect(() => {
  //   let x = productsList.find((p) => p.id === prod_id);
  //   setProduct(x);
  // }, [prod_id]);

  useEffect(() => {
    async function fetchProdOnline() {
      await axios
        .get(`${process.env.REACT_APP_API_URL}/product/${prod_id}/`)
        .catch((_) => {})
        .then((response) => {
          if (response && response.status === 200) {
            setProduct(response.data);
          }
        });
    }

    fetchProdOnline();
  }, [prod_id]);

  const onChange = (e) => {
    if (parseInt(e.target.value)) {
      if (e.target.value > 0 && product?.backorders_allowed) {
        dispatch(
          alterCart({
            id: id,
            prod_id: prod_id,
            quantity: parseInt(e.target.value),
            meta_data: meta_data,
          })
        );
      } else if (
        e.target.value > 0 &&
        e.target.value <= product?.stock_quantity
      ) {
        dispatch(
          alterCart({
            id: id,
            prod_id: prod_id,
            quantity: parseInt(e.target.value),
            meta_data: meta_data,
          })
        );
      }
    }
  };

  const onAdd = () => {
    if (product?.backorders_allowed) {
      dispatch(
        alterCart({
          id: id,
          prod_id: prod_id,
          quantity: quantity + 1,
          meta_data: meta_data,
        })
      );
    } else if (quantity + 1 <= product?.stock_quantity) {
      dispatch(
        alterCart({
          id: id,
          prod_id: prod_id,
          quantity: quantity + 1,
          meta_data: meta_data,
        })
      );
    }
  };

  const onMinus = () => {
    if (quantity > 1) {
      dispatch(
        alterCart({
          id: id,
          prod_id: prod_id,
          quantity: quantity - 1,
          meta_data: meta_data,
        })
      );
    } else {
      dispatch(deleteCart({ id: id, prod_id: prod_id }));
    }
  };

  return (
    product && (
      <>
        {alt_display ? (
          <Row md={12} className="cart_container_alt">
            <Col sm={6}>
              <img
                src={`${process.env.REACT_APP_API_URL}${
                  product.images[0]?.src ?? ""
                }`}
                alt={product.images[0]?.alt ?? ""}
              />
              <div className="cart_container_name">
                <p>
                  <Link to={`/shop/${product.name}-${product.id}/`}>
                    {product?.name}
                  </Link>
                </p>
              </div>
            </Col>
            <Col sm={6}>
              <X
                className="x_svg"
                size={25}
                onClick={() =>
                  dispatch(deleteCart({ id: id, prod_id: prod_id }))
                }
              />
              Ksh. {formatCurrency(product?.price)}
              <MinusPlusContainer
                val={quantity}
                onChange={onChange}
                onAdd={onAdd}
                onMinus={onMinus}
                max={product?.stock_quantity}
                backOrdersAllowed={product?.backorders_allowed}
                disabled={product?.stock_status !== "instock"}
              />
              Ksh. {formatCurrency(product?.price * quantity)}
            </Col>
          </Row>
        ) : (
          <tr className="cart_container">
            <td>
              <img
                src={`${process.env.REACT_APP_API_URL}${
                  product.images[0]?.src ?? ""
                }`}
                alt={product.images[0]?.alt ?? ""}
              />
              <div className="cart_container_name">
                <p>
                  <Link to={`/shop/${product.name}-${product.id}/`}>
                    {product?.name}
                  </Link>
                </p>
              </div>
            </td>
            <td>Ksh. {formatCurrency(product?.price)} </td>
            <td>
              <MinusPlusContainer
                val={quantity}
                onChange={onChange}
                onAdd={onAdd}
                onMinus={onMinus}
                max={product?.stock_quantity}
                backOrdersAllowed={product?.backorders_allowed}
                disabled={product?.stock_status !== "instock"}
              />
            </td>
            <td>Ksh. {formatCurrency(product?.price * quantity)} </td>
            <td>
              <Trash
                onClick={() =>
                  dispatch(deleteCart({ id: id, prod_id: prod_id }))
                }
              />
            </td>
          </tr>
        )}
      </>
    )
  );
};

export default CartContainer;
