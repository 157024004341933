import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { basicConfig } from "../../constants";

export const fetchCart = createAsyncThunk(
  "cart/check",
  async (_, { getState, dispatch }) => {
    const { user } = getState();

    let localstorage_cartItems =
      JSON.parse(localStorage.getItem("trf_cart")) || [];

    if (user.user?.email) {
      // logged in
      if (localstorage_cartItems.length > 0) {
        // first upload them
        const body = JSON.stringify({
          customer: user.user?.email,
          cart_items: localstorage_cartItems,
        });

        try {
          await axios.post(
            `${process.env.REACT_APP_API_URL}/orders/carts/`,
            body,
            basicConfig
          );

          localStorage.removeItem("trf_cart");
        } catch (e) {
          // console.log(e);
        }
      }
      // then fetch

      const body = JSON.stringify({ customer: user.user?.email });

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/orders/filter_cart/`,
        body,
        basicConfig
      );

      const res = await response.data;

      return res;
    } else {
      // guest, add to local storage

      return { cart_items: localstorage_cartItems };
    }
  }
);

export const alterCart = createAsyncThunk(
  "cart/alter",
  async ({ id, prod_id, quantity, meta_data }, { getState, dispatch }) => {
    const { user, cart } = getState();

    let cart_dict = {
      id: id,
      prod_id: prod_id,
      quantity: quantity,
      meta_data: meta_data,
    };

    if (user.user?.email) {
      // logged in
      // console.log("User logged in");
      if (id) {
        // console.log("Update");

        const body = JSON.stringify(cart_dict);

        const response = await axios.put(
          `${process.env.REACT_APP_API_URL}/orders/cart_item/${id}/`,
          body,
          basicConfig
        );

        const updated_cart_dict = await response.data;

        let cartItems = [...cart.shopping_cart];

        let i = cartItems.findIndex((x) => x.id === id);

        // console.log("found at", i);

        cartItems[i] = updated_cart_dict;

        return cartItems;
      } else {
        // console.log("Create");

        const body = JSON.stringify({
          customer: user.user?.email,
          cart_items: [cart_dict],
        });

        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/orders/carts/`,
          body,
          basicConfig
        );

        const res = await response.data;

        return res.cart_items;
      }
    } else {
      let cartItems = JSON.parse(localStorage.getItem("trf_cart")) || [];

      const i = cartItems.findIndex((x) => x.prod_id === prod_id);

      if (i < 0) {
        // not in list
        cartItems.push(cart_dict);
      } else {
        // found, replace with new data
        cartItems[i] = cart_dict;
      }

      localStorage.setItem("trf_cart", JSON.stringify(cartItems));

      //   dispatch(fetchCart());
      // no need to dispatch fetchItems we can update state of cart shopping_cart list here

      return cartItems;
    }
  }
);

export const deleteCart = createAsyncThunk(
  "cart/delete",
  async ({ id, prod_id }, { getState, dispatch }) => {
    const { user } = getState();

    if (user.user?.email) {
      // logged in

      // console.log("deleting from db");

      await axios.delete(
        `${process.env.REACT_APP_API_URL}/orders/cart_item/${id}/`,
        basicConfig
      );
    } else {
      let cartItems = JSON.parse(localStorage.getItem("trf_cart")) || [];

      const new_cartItems = cartItems.filter((x) => x.prod_id !== prod_id);

      localStorage.setItem("trf_cart", JSON.stringify(new_cartItems));

      // //   dispatch(fetchCart());

      // return new_cartItems;
    }

    dispatch(fetchCart());
  }
);

export const emptyCart = createAsyncThunk(
  "cart/empty",
  async (_, { getState, dispatch }) => {
    const { user, cart } = getState();

    if (user.user?.email) {
      // logged in

      await axios.post(
        `${process.env.REACT_APP_API_URL}/orders/empty_cart/`,
        { id: cart?.shopping_cart_id },
        basicConfig
      );
    } else {
      localStorage.removeItem("trf_cart");
    }

    dispatch(fetchCart());
  }
);

export const tallyCart = createAsyncThunk(
  "cart/tally",
  async (_, { getState }) => {
    const { cart } = getState();

    let sub_total = 0;

    let shopping_cart = cart?.shopping_cart || [];

    for (const item of shopping_cart) {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/product/${item?.prod_id}/`
      );

      if (response && response.status === 200) {
        const prod = response.data;

        if (
          (prod.stock_status === "instock" || prod.backorders_allowed) &&
          prod.purchasable &&
          (prod.stock_quantity >= item.quantity || prod.backorders_allowed)
        ) {
          sub_total += prod.price * item.quantity;
        }
      }
    }

    return sub_total;
  }
);
