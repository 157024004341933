import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { ArrowRepeat, Heart, Whatsapp } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import { alterCart } from "../store/cart/CartActions";
import ProductQuickView from "./ProductQuickView";
import noImage from "../assets/images/no-image.png";
import { formatCurrency } from "../common_methods/formatCurrency";
import { addWishlist } from "../store/wishlist/wishlistAction";

const SecondProductContainer = ({ product, showPercentageOff }) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [percentOff, setPercentOff] = useState(0);

  const [showQuickView, setShowQuickView] = useState(false);

  const { loading, shopping_cart } = useSelector((state) => state.cart);

  const { isAuthenticated } = useSelector((state) => state.user);

  useEffect(() => {
    function calculatePercentOff() {
      let perc = 0;
      if (product) {
        if (product.price && product.regular_price) {
          perc =
            ((product.regular_price - product.price) / product.regular_price) *
            100;
        }
      }

      return perc;
    }
    setPercentOff(calculatePercentOff());
  }, [product]);

  return (
    <>
      <div
        className="second_product_container col-sm-6"
        itemScope
        itemType="https://schema.org/Product"
      >
        <div className="col-sm-6 second_prod_image">
          <div className="second_product_extended_actions">
            <div className="second_product_extended_actions_div">
              <Button
                onClick={() =>
                  isAuthenticated
                    ? dispatch(addWishlist({ prod_id: product?.id }))
                    : navigate("/login/")
                }
              >
                <Heart size={24} color="grey" />
              </Button>

              <Link
                to={`https://wa.me/254706803305/?text=${encodeURIComponent(
                  "Hello, I would like to purchase \n\nProduct :" +
                    `${product.name}` +
                    "\nPrice : Ksh " +
                    `${product.price}` +
                    `\nURL : ${encodeURI(
                      `https://www.trf.co.ke/shop/${product.name}-${product.id}`
                    )}` +
                    "/\n\nThank you."
                )}`}
                target="_blank"
              >
                <Whatsapp size={24} color="#87c587" type="icon" />{" "}
              </Link>

              <Button
                onClick={() => {
                  setShowQuickView(true);
                }}
              >
                <ArrowRepeat size={28} color="grey" />
              </Button>
            </div>
          </div>
          {showPercentageOff && percentOff > 0 ? (
            <div className="percantage_off"> -{percentOff.toFixed(1)}% </div>
          ) : (
            <></>
          )}
          <div className="second_prod_image_container">
            <Link to={`/shop/${product.name}-${product.id}/`}>
              <img
                className="second_prod_image"
                itemProp="image"
                src={
                  product.images[0]?.src
                    ? `${process.env.REACT_APP_API_URL}` +
                      `${product.images[0]?.src}`
                    : `${noImage}`
                }
                alt={product.name}
              />
            </Link>
          </div>
        </div>
        <div className="col-sm-6 second_prod_details">
          <p itemProp="name">
            <Link to={`/shop/${product.name}-${product.id}/`}>
              {product.name}
            </Link>
          </p>
          {/* <StarRating itemProp="aggregateRating" itemScope itemType="https://schema.org/AggregateRating" value={product.average_rating} /> */}
          <h5 itemProp="offers" itemScope itemType="https://schema.org/Offer">
            <a
              itemprop="availability"
              href="https://schema.org/InStock"
              style={{ display: "none" }}
            ></a>
            <a
              itemprop="priceValidUntil"
              content="2030-12-30"
              style={{ display: "none" }}
            ></a>
            <span itemProp="priceCurrency" content="KES">
              Ksh.
            </span>{" "}
            <span itemProp="price" content={product.price}>
              {formatCurrency(product.price)}
            </span>
          </h5>
          <p>
            {percentOff > 0 ? (
              <span className="off_price">
                Ksh. {formatCurrency(product.regular_price)}
              </span>
            ) : (
              <span>
                <br />{" "}
              </span>
            )}
          </p>
          <span itemprop="description" style={{ display: "none" }}>
            {product?.description}
          </span>

          <div className="second_product_container_btn">
            {(shopping_cart || []).some((c) => c.prod_id === product.id) ? (
              <Button disabled>ADDED</Button>
            ) : (
              <Button
                onClick={() =>
                  dispatch(
                    alterCart({
                      prod_id: product.id,
                      quantity: 1,
                      meta_data: [],
                    })
                  )
                }
                disabled={loading}
              >
                Add To Cart
              </Button>
            )}
          </div>
        </div>
      </div>
      <ProductQuickView
        product={product}
        show={showQuickView}
        setShow={setShowQuickView}
      />
    </>
  );
};

export default SecondProductContainer;
