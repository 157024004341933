import React, { useEffect, useState } from "react";
import { Alert, Button, Form, Spinner, Table } from "react-bootstrap";
import AdminPage from "../AdminPage";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import {
  gmtDate,
  localizeDate,
  valuefyDate,
} from "../../../common_methods/formatDate";
import OrderLineItem from "../../orders/OrderLineItem";
import { basicConfigMultipart } from "../../../constants";
import { isArray, return_ids_from_objects } from "../../../CommonMethods";

const status_choices = [
  "pending",
  "completed",
  "cancelled",
  "refunded",
  "trash",
];

const OrderForm = () => {
  const { pk } = useParams();

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [showError, setShowError] = useState(false);

  const [order, setOrder] = useState({});

  const [order_status, setOrderStatus] = useState(null);

  const [formValidated, setFormValidated] = useState(false);

  async function fetchOrderById(id) {
    setShowError(false);
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/orders/order/${id}/`)
      .catch((_) => {
        setShowError(true);
      })
      .then((response) => {
        if (response && response.status === 200) {
          setOrder(response.data);
        } else {
          setShowError(true);
        }
      });

    setLoading(false);
  }
  useEffect(() => {
    //   fetch the item using pk as id
    if (pk) {
      fetchOrderById(pk);
    }
  }, [pk]);

  useEffect(() => {
    setOrderStatus(order?.status);
  }, [order]);

  useEffect(() => {
    if (order_status !== "completed") {
      setOrder((pre) => ({
        ...pre,
        date_paid_gmt: null,
        date_completed_gmt: null,
      }));
    }
  }, [order_status]);

  async function handleSubmit(e) {
    e.preventDefault();
    const form = e.currentTarget;
    window.scrollTo(0, 0);
    if (form.checkValidity() === false) {
      e.stopPropagation();
      setFormValidated(true);
    } else {
      setShowError(false);
      setFormValidated(false);
      setLoading(true);

      let modified_order = {
        ...order,

        needs_payment: order.status === "pending",
      };

      let line_items_ids = [];

      for (const lineItem of order.line_items) {
        const id = await return_ids_from_objects(lineItem, ``, false);
        if (id !== null) {
          line_items_ids.push(id);
        }
      }

      modified_order.line_items = line_items_ids;

      modified_order.billing = modified_order?.billing?.id ?? null;

      modified_order.shipping = modified_order?.shipping?.id ?? null;

      const formData = new FormData();

      for (const [key, value] of Object.entries(modified_order)) {
        if (value != null) {
          if (isArray(value)) {
            value.forEach((i) => {
              formData.append(`${key}`, i);
            });
          } else {
            formData.append(`${key}`, value);
          }
        }
      }

      await axios
        .put(
          `${process.env.REACT_APP_API_URL}/orders/order/${pk}/`,
          formData,
          basicConfigMultipart
        )
        .catch((e) => {
          setShowError(true);
        })
        .then((response) => {
          if (response && response.status === 200) {
            navigate("/admin/orders/");
          } else {
            setShowError(true);
          }
        });

      setLoading(false);
    }
  }
  return (
    <AdminPage>
      {showError && (
        <Alert
          variant="danger"
          autoFocus={true}
          onClose={() => {
            setShowError(false);
          }}
          dismissible
        >
          {window.scrollTo(0, 0)}
          <Alert.Heading>Error. Try Again.</Alert.Heading>
        </Alert>
      )}
      {loading && <Spinner animation="grow" />}
      <Form
        noValidate
        validated={formValidated}
        onSubmit={(e) => handleSubmit(e)}
      >
        <div className="admin_order_form">
          <Table striped bordered>
            <tbody>
              <tr>
                <td>#id</td>
                <td>{order?.id}</td>
              </tr>
              <tr>
                <td>Status</td>
                <td>
                  <Form.Select
                    value={order?.status}
                    onChange={(e) =>
                      setOrder({ ...order, status: e.target.value })
                    }
                  >
                    {status_choices.map((s, index) => {
                      return (
                        <option value={s} key={index}>
                          {s}
                        </option>
                      );
                    })}
                  </Form.Select>
                </td>
              </tr>
              <tr>
                <td>Currency</td>
                <td>{order?.currency}</td>
              </tr>
              <tr>
                <td>Date Created</td>
                <td>{localizeDate(order?.date_created_gmt)}</td>
              </tr>
              <tr>
                <td>Shipping Charge</td>
                <td>{order?.shipping_total}</td>
              </tr>
              <tr>
                <td>Grand Total</td>
                <td>{order?.total}</td>
              </tr>
              <tr>
                <td>Note</td>
                <td>{order?.customer_note ?? ""}</td>
              </tr>
              <tr>
                <td>Payment Method</td>
                <td>{order?.payment_method}</td>
              </tr>
              <tr>
                <td>Customer</td>
                <td>{order?.customer ?? "Guest"}</td>
              </tr>
              <tr>
                <td>Billing</td>
                <td>
                  <Table>
                    <tbody>
                      <tr>
                        <td>Names</td>
                        <td>
                          {order?.billing?.first_name}{" "}
                          {order?.billing?.last_name}
                        </td>
                      </tr>
                      <tr>
                        <td>Email</td>
                        <td>{order?.billing?.email}</td>
                      </tr>
                      <tr>
                        <td>Phone Number</td>
                        <td>{order?.billing?.phone_number}</td>
                      </tr>
                      <tr>
                        <td>Address</td>
                        <td>
                          {order?.billing?.address}, {order?.billing?.town},{" "}
                          {order?.billing?.county}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </td>
              </tr>
              <tr>
                <td>Shipping</td>
                <td>
                  {order?.shipping != null ? (
                    <Table>
                      <tbody>
                        <tr>
                          <td>Names</td>
                          <td>
                            {order?.shipping?.first_name}{" "}
                            {order?.shipping?.last_name}
                          </td>
                        </tr>
                        <tr>
                          <td>Email</td>
                          <td>{order?.shipping?.email}</td>
                        </tr>
                        <tr>
                          <td>Phone Number</td>
                          <td>{order?.shipping?.phone_number}</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>
                            {order?.shipping?.address}, {order?.shipping?.town},{" "}
                            {order?.shipping?.county}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  ) : (
                    <>Pick Up At Station</>
                  )}
                </td>
              </tr>
              <tr>
                <td>Line Items</td>
                <td>
                  {(order?.line_items ?? []).map((item, index) => {
                    return (
                      <OrderLineItem
                        key={index}
                        item={item}
                        index={index + 1}
                      />
                    );
                  })}
                </td>
              </tr>
              <tr>
                <td>Paid On</td>
                <td>
                  <Form.Control
                    type="datetime-local"
                    value={
                      order.status !== "completed"
                        ? ""
                        : valuefyDate(order?.date_paid_gmt)
                    }
                    required={order_status === "completed"}
                    disabled={order_status === "pending"}
                    onChange={(e) => {
                      setOrder({
                        ...order,
                        date_paid_gmt: gmtDate(e.target.value),
                      });
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td>Completed On</td>
                <td>
                  <Form.Control
                    type="datetime-local"
                    value={
                      order.status !== "completed"
                        ? ""
                        : valuefyDate(order?.date_completed_gmt)
                    }
                    required={order_status === "completed"}
                    disabled={order_status === "pending"}
                    onChange={(e) => {
                      setOrder({
                        ...order,
                        date_completed_gmt: gmtDate(e.target.value),
                      });
                    }}
                  />
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
        <Button className="form-submit-btn" variant="primary" type="submit">
          UPDATE
        </Button>
      </Form>
    </AdminPage>
  );
};

export default OrderForm;
