import React from "react";
import Banner from "./Banner";
// import Categories from "./Categories";
import PopularProducts from "./PopularProducts";
import WhatClientsSay from "./WhatClientsSay";
import DealsOfTheWeek from "./DealsOfTheWeek";
import BestSellers from "./BestSellers";
import { Container, Row } from "react-bootstrap";

const Home = () => {
  return (
    <div>
      <h1 style={{ display: "none" }}>
        Providing top-quality furniture and home decor items across Kenya.
      </h1>
      <Banner />
      <br />
      <DealsOfTheWeek />
      <Container>
        <Row className="quote_row">
          <blockquote class="blockquote text-right">
            <p>
              A house is made of walls and beams; a home is built with love and
              dreams.
            </p>
            <footer class="blockquote-footer">
              <cite title="Source Title">Ralph Waldo</cite>
            </footer>
          </blockquote>
        </Row>
      </Container>
      <BestSellers />
      <Container>
        <Row className="quote_row">
          <blockquote class="blockquote text-right">
            <p>
              Our homes are our sanctuaries, personal expressions of our inner
              selves. They should be an oasis of calm, a place where we can
              relax and be ourselves. By carefully choosing decor that resonates
              with us, we create a space that speaks to our hearts and nurtures
              our souls.
            </p>
            <footer class="blockquote-footer">
              <cite title="Source Title">Unknown</cite>
            </footer>
          </blockquote>
        </Row>
      </Container>
      <PopularProducts />
      <br />
      <WhatClientsSay />
    </div>
  );
};

export default Home;
