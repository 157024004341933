import React, { useContext, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FilterContext } from "../../context/FilterContext";
import { Button, Col, Container, Row } from "react-bootstrap";
import PriceRange from "../../components/Filter/PriceRange";
import SearchSelectList from "../../components/Filter/SearchSelectList";
import PageProductsListing from "../../components/PageProductsListing";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet-async";
import { List, X } from "react-bootstrap-icons";

const Catalog = () => {
  const location = useLocation();

  const { categories, colors, seats } = useSelector((state) => state.data);

  const urlParams = new URLSearchParams(location.search);

  const [show, setShow] = useState(false);

  const search = urlParams.get("q");

  const {
    minPrice,
    maxPrice,
    setMinPrice,
    setMaxPrice,
    filterbrands,
    filtercolors,
    setColors,
    filterseats,
    setSeats,
  } = useContext(FilterContext);

  return (
    <>
      <Helmet>
        <title>Catalog</title>
        <meta
          name="description"
          content="Browse through Thika Road Furnitures Catalog."
        />
        <link rel="canonical" href="/catalog" />
      </Helmet>
      <div className="catalogPage">
        <Container className="custom_container">
          <div className="followLinks">
            <Link to={"/"}>Home </Link>/ <b>Catalog </b>
          </div>
          <div className="toggleShowFilter">
            <Button onClick={() => setShow(!show)}>
              <List /> Filter
            </Button>
          </div>

          <Row>
            <Col
              sm={4}
              className={show ? "pageFilters  showFilter" : "pageFilters "}
            >
              <X
                size={30}
                className="closeFilter"
                onClick={() => setShow(false)}
              />
              <h5>CATEGORY</h5>
              <div className="categLvlLinks">
                {(categories || [])
                  .filter((x) => x.top_level)
                  .map((category, index) => {
                    return (
                      <Link
                        to={`/product-category/${category.name}/?q=${search}`}
                        key={index}
                        className="topLvlCategLinks"
                      >
                        {category.name}
                      </Link>
                    );
                  })}
              </div>
              <hr />
              <h5>PRICE</h5>
              <PriceRange
                minPrice={minPrice}
                maxPrice={maxPrice}
                setUpMax={setMaxPrice}
                setUpMin={setMinPrice}
              />
              <hr />
              {/* <h5>MAKE/BRAND</h5>
              <SearchSelectList
                items={brands || []}
                keyName="name"
                setSelected={setBrands}
                selectedItems={filterbrands}
              />
              <hr /> */}
              <h5>Number of seats</h5>
              <SearchSelectList
                items={seats || []}
                keyName={"value"}
                setSelected={setSeats}
                selectedItems={filterseats}
                showSearch={false}
              />
              <h5>COLOR</h5>
              <SearchSelectList
                items={colors || []}
                keyName={"value"}
                setSelected={setColors}
                selectedItems={filtercolors}
              />
            </Col>
            <Col sm={8} className="pageProductsListing">
              <PageProductsListing
                brand={filterbrands}
                color={filtercolors}
                seats={filterseats}
                minPrice={minPrice}
                maxPrice={maxPrice}
                search={search}
                stillComputing={false}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Catalog;
