import React from "react";
import { Col, Row } from "react-bootstrap";
import { formatCurrency } from "../../common_methods/formatCurrency";

const CheckoutCartContainer = ({ line_item }) => {
  const { name, quantity, price } = line_item;

  return (
    <Row>
      <Col style={{ width: "80%" }}>
        {name} x {quantity}
      </Col>
      <Col style={{ width: "20%" }}>
        Ksh. {formatCurrency(price * quantity)}
      </Col>
    </Row>
  );
};

export default CheckoutCartContainer;
